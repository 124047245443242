import { connect } from 'react-redux'
import OrganizationListComponent from './OrganizationList'
import AddOrganizationComponent from './AddOrganization'
import EditOrganizationComponent from './EditOrganization'
import OrganizationsActions from '../../../../modules/organizations'
import CommonActions from '../../../../modules/common'
import ConfirmationDialogActions from '../../../../modules/confirmation-dialog'

const mapStateToProps = state => {
  return {
    submitting: state.organizations.submitting,
    fetching: state.organizations.fetching,
    isLoading: state.organizations.isLoading,
    organizations: state.organizations.scopedOrgs,
    allOrgs: state.organizations.organizations,
    editingOrganization: state.organizations.editingOrganization,
    isAdmin: state.auth.isAdmin,
    myOrganizations: state.auth.organizations,
    total: state.organizations.total,
    filter: state.organizations.filter,
    currentOrgId: state.organizations.selectedOrganizationId
  }
}

export const OrganizationList = connect(
  mapStateToProps,
  { ...OrganizationsActions, ...CommonActions, ...ConfirmationDialogActions }
)(OrganizationListComponent)

export const AddOrganization = connect(
  mapStateToProps,
  { ...OrganizationsActions, ...CommonActions }
)(AddOrganizationComponent)

export const EditOrganization = connect(
  mapStateToProps,
  { ...OrganizationsActions, ...CommonActions }
)(EditOrganizationComponent)
