import React, { Component } from 'react'
import { FormGroup, Label } from 'reactstrap'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import { setTimeToSelectedTimeZone } from '../../utils/util-helper'

export class DateRangePicker extends Component {
  handleChangeStart = startDate => {
    let endDate = this.props.input.value[1]

    if (startDate > endDate) {
      endDate = null
    }

    this.props.input.onChange([startDate, endDate])
  }

  handleChangeEnd = endDate => {
    let startDate = this.props.input.value[0]

    if (startDate > endDate) {
      startDate = null
    }

    this.props.input.onChange([startDate, endDate])
  }

  setValue = date => {
    return date && date !== '' ? date : null
  }

  getMinDate = () => {
    let minDate = new Date()
    if (this.props.timezone) {
      minDate = moment.utc().toDate()
      setTimeToSelectedTimeZone(minDate, this.props.timezone, true)
    }
    return minDate
  }

  render() {
    const {
      label,
      disabled,
      showTime,
      input,
      meta: { error, touched }
    } = this.props
    return (
      <FormGroup className={touched && error ? 'is-invalid' : ''}>
        <Label>{label}</Label>
        <div className="custom-date-range-picker">
          <ReactDatePicker
            selected={this.setValue(input.value[0])}
            onChange={this.handleChangeStart}
            className={
              touched && error ? 'form-control is-invalid' : 'form-control'
            }
            placeholderText="Start Date"
            dateFormat="MM/dd/yyyy h:mm aa"
            disabled={disabled}
            showTimeSelect={showTime}
            minDate={this.getMinDate()}
          />
          <ReactDatePicker
            selected={this.setValue(input.value[1])}
            onChange={this.handleChangeEnd}
            className={
              touched && error ? 'form-control is-invalid' : 'form-control'
            }
            placeholderText="End Date"
            dateFormat="MM/dd/yyyy h:mm aa"
            disabled={disabled}
            showTimeSelect={showTime}
          />
        </div>
        {this.showErrorLabel(touched, error)}
      </FormGroup>
    )
  }

  showErrorLabel = (touched, error) => {
    if (touched && error) {
      return <div className="invalid-feedback">{error}</div>
    }
    return null
  }
}

export default DateRangePicker
