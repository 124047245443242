import { connect } from 'react-redux'
import DefaultLayout from './DefaultLayout'
import AuthActions from '../../modules/auth'
import CommonActions from '../../modules/common'
import OrganizationsActions from '../../modules/organizations'

const mapStateToProps = state => {
  return {
    name: state.auth.name,
    isAdmin: state.auth.isAdmin,
    myOrgs: state.auth.organizations,
    organizations: state.organizations.organizations,
    userId: state.auth.userId,
    currentOrganization: state.organizations.selectedOrganizationId
  }
}

export default connect(
  mapStateToProps,
  { ...AuthActions, ...OrganizationsActions, ...CommonActions }
)(DefaultLayout)
