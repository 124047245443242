import { connect } from 'react-redux'
import Signup from './Signup'
import AuthActions from '../../../../modules/auth'

const mapStateToProps = state => {
    return {
        fetching: state.auth.fetching
    }
}

export default connect(
    mapStateToProps,
    {
        ...AuthActions
    }
)(Signup)
