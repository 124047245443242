import { call, put, select } from 'redux-saga/effects'
import { callApi, isOk } from './helper'
import apiServices from '../api-services'
import PromotionActions from '../modules/promotions'
import CommonActions from '../modules/common'
import {
  showErrorResponse,
  showInfoMessage,
  showSuccessMessage
} from '../utils/notification-handler'

export function* requestPromotions({ paging }) {
  if (paging) {
    yield put(PromotionActions.updateFilter(paging))
  }
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.getPromotions, orgId, paging)
  if (isOk(response)) {
    yield put(PromotionActions.onPromotionsRequestSuccess(response.data))
  } else {
    yield call(errorHandler, response)
  }
}

export function* deletePromotion({ id, name }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.deletePromotion, orgId, id)
  if (isOk(response)) {
    showSuccessMessage(`Promotion "${name}" has been deleted successfully.`)
    yield put(PromotionActions.onDeletePromotionSuccess(id))
  } else {
    yield call(errorHandler, response)
  }
}

export function* savePromotion({ formData }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const featuredImg = formData.featuredImage
    ? formData.prizes[Number(formData.featuredImage)][2]
    : formData.prizes[0][2]
  const imgResponse = yield callApi(apiServices.uploadImage, orgId, featuredImg)

  for (let i = 0; i < formData.prizes.length; i++) {
    let imageResponse = yield callApi(
      apiServices.uploadImage,
      orgId,
      formData.prizes[i][2]
    )
    formData.prizes[i][2] = imageResponse.data.key
  }

  if (isOk(imgResponse)) {
    const apiData = mapFormDataToApiFormat(formData)
    apiData.heroUri = imgResponse.data.key

    const response = yield callApi(apiServices.savePromotion, orgId, apiData)

    if (isOk(response)) {
      showSuccessMessage(`Promotion has been added successfully.`)
      yield put(PromotionActions.savePromotionSuccess(response.data))
      yield put(CommonActions.navigateTo('/promotions'))
    } else {
      yield call(errorHandler, response)
    }
  } else {
    yield call(errorHandler, imgResponse)
  }
}

export function* editPromotion({ formData }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  let response = { ok: true }

  const apiData = mapFormDataToApiFormat(formData)

  if (isOk(response)) {
    response = yield callApi(
      apiServices.editPromotion,
      orgId,
      apiData.promotionId,
      apiData
    )

    if (isOk(response)) {
      showSuccessMessage(`Promotion has been edited successfully.`)
      yield put(PromotionActions.editPromotionSuccess(response.data))
      yield put(CommonActions.navigateTo('/promotions'))
    } else {
      yield call(errorHandler, response)
    }
  }
}

export function* getPromotionDetails({ id, version }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.getPromotion, orgId, id, version)
  if (isOk(response)) {
    yield put(PromotionActions.onPromotionDetailsSuccess(response.data))
  } else {
    yield call(errorHandler, response)
  }
}

export function* publishPromotion({ id, name, version }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.publishPromotion, orgId, id)
  if (isOk(response)) {
    showSuccessMessage(`Promotion "${name}" has been published successfully.`)
    yield put(PromotionActions.onPublishPromotionSuccess(id, version))
    yield put(CommonActions.navigateTo('/promotions'))
  } else {
    yield call(errorHandler, response)
  }
}

export function* submitAmoeEntries({ id, numOfEntries }) {
  const response = yield callApi(
    apiServices.submitAmoeEntries,
    id,
    numOfEntries
  )
  if (isOk(response)) {
    showSuccessMessage('AMOE entries have been submitted successfully.')
    yield put(PromotionActions.onAmoeEntriesSuccess(id))
  } else {
    yield call(errorHandler, response)
  }
}

export function* submitAmoeWinner({ id, data }) {
  const response = yield callApi(apiServices.submitAmoeWinner, id, data)
  if (isOk(response)) {
    showSuccessMessage('Winner data has been saved successfully.')
    yield put(PromotionActions.onAmoeEntriesSuccess(id))
  } else {
    yield call(errorHandler, response)
  }
}

export function* playAmoeGame({ promotionId }) {
  const response = yield callApi(apiServices.playAmoeGame, promotionId)
  if (isOk(response)) {
    if (response.data.isWinner) {
      showSuccessMessage('Winning game! Please, fill up the winner info.')
    } else {
      showInfoMessage('Not a winner game...')
      yield put(PromotionActions.hideAmoeScreen())
      yield put(CommonActions.navigateTo('/promotions'))
    }
    yield put(PromotionActions.onPlayAmoeGameSuccess())
  } else {
    yield put(PromotionActions.hideAmoeScreen())
    yield put(CommonActions.navigateTo('/promotions'))
    yield call(errorHandler, response)
  }
}

export function* getWinners({ promotionId }) {
  const response = yield callApi(apiServices.getWinners, promotionId)
  if (isOk(response)) {
    yield put(PromotionActions.getWinnersSuccess(response.data))
  }
}

export function* downloadWinners({ start, end, includePrevious }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(
    apiServices.downloadWinners,
    orgId,
    start,
    end,
    includePrevious
  )
  if (isOk(response)) {
    yield put(PromotionActions.downloadWinnersSuccess(response.data))
  }
}

const mapFormDataToApiFormat = formData => {
  const apiData = {
    ...formData
  }

  apiData.startDate = apiData.startEndDate[0]
  apiData.endDate = apiData.startEndDate[1]
  apiData.prizes = []

  if (!apiData.numberOfPrizes) {
    apiData.numberOfPrizes = 1
  }

  for (let i = 0; i < formData.prizes.length; i++) {
    for (let j = 0; j < formData.prizes[i][1]; j++) {
      if (j === 0) {
        apiData.prizes.push({
          productNumber: formData.prizes[i][0],
          imageUrl: formData.prizes[i][2],
          description: formData.prizes[i][3]
        })
      } else {
        apiData.prizes.push({
          productNumber: formData.prizes[i][0]
        })
      }
    }
  }

  delete apiData.numberOfPrizes
  delete apiData.startEndDate
  return apiData
}

function* errorHandler(response) {
  if (response.data) {
    showErrorResponse(response)
  }
  yield put(PromotionActions.onError())
}
