import React, { Component, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav
} from '@coreui/react'
// sidebar nav config
import { NavAdmin, navAnalyst } from './_nav'
// routes config
import routes from '../../routes/authenticated'
import AuthenticatedUser from '../../components/AuthenticatedUser'
import { Preloader } from '../../components/common'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'

class DefaultLayout extends Component {
  logOut = e => {
    e.preventDefault()
    this.props.logout()
    this.props.navigateTo('/login')
  }

  render() {
    const {
      name,
      organizations,
      currentOrganization,
      isAdmin,
      myOrgs
    } = this.props
    const canAddOrg = myOrgs && myOrgs.length === 0
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={<Preloader />}>
            <DefaultHeader
              username={name}
              organizations={organizations}
              currentOrganization={currentOrganization}
              onLogout={e => this.logOut(e)}
            />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader>
              <span className="organization-name">Organization</span>
              {organizations && organizations.length > 0 ? (
                <select
                  className="custom-select"
                  value={currentOrganization || ''}
                  onChange={evt =>
                    this.props.setOrganizationId(evt.target.value)
                  }
                >
                  {organizations.map(x => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
                </select>
              ) : (
                ' N/A'
              )}
            </AppSidebarHeader>
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav
                navConfig={isAdmin ? NavAdmin : navAnalyst(canAddOrg)}
                {...this.props}
              />
            </Suspense>
            <AppSidebarFooter />
            {/* <AppSidebarMinimizer /> */}
          </AppSidebar>
          <main className="main">
            {/* <AppBreadcrumb appRoutes={routes} /> */}
            <Container fluid>
              <Suspense fallback={<Preloader />}>
                <AuthenticatedUser>
                  <div className="page-wrapper">
                    <Switch>
                      {routes.map((route, idx) => {
                        return route.component ? (
                          <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => <route.component {...props} />}
                          />
                        ) : null
                      })}
                      <Redirect from="/" to="/home" />
                    </Switch>
                  </div>
                </AuthenticatedUser>
              </Suspense>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Suspense fallback={<Preloader />}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    )
  }
}

export default DefaultLayout
