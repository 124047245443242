import { createReducer, createActions } from 'reduxsauce'
import { OrganizationsTypes } from './organizations'

const { Types, Creators } = createActions(
  {
    organizationInvitesRequest: ['paging'],
    organizationInvitesRequestSuccess: ['invites'],
    inviteUser: ['formData'],
    inviteUserSuccess: ['invite'],
    removeInvite: ['inviteId'],
    removeInviteSuccess: ['inviteId'],
    resendInvite: ['inviteId'],
    acceptInvite: ['token'],
    acceptInviteSuccess: null,
    declineInvite: ['token'],
    declineInviteSuccess: null,
    updateFilter: ['filter'],
    onError: ['error']
  },
  {
    prefix: 'INVITES_'
  }
)

export const InvitesTypes = Types

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  invites: null,
  fetching: false,
  error: null,
  filter: {
    offset: 0,
    q: '',
    status: ''
  }
}

/* ------------- Reducers ------------- */

export const request = state => {
  return {
    ...state,
    fetching: true,
    error: null
  }
}

export const requestWithPaging = (state, { paging }) => {
  return {
    ...state,
    fetching: !paging, // if changing page, don't fetch but use isLoading
    isLoading: !!paging
  }
}

export const onError = (state, action) => {
  return {
    ...state,
    fetching: false,
    error: action.error
  }
}

export const organizationInvitesRequestSuccess = (state, { invites }) => {
  return {
    ...state,
    fetching: false,
    isLoading: false,
    invites: invites.data,
    total: invites.meta.total
  }
}

export const inviteUserSuccess = (state, { invite }) => {
  return {
    ...state,
    invites: state.invite ? [...state.invites, invite] : null,
    fetching: false
  }
}

export const removeInviteSuccess = (state, { inviteId }) => {
  return {
    ...state,
    fetching: false,
    invites: state.invites.filter(x => x.id !== inviteId)
  }
}

export const resetInvites = state => {
  return {
    ...state,
    invites: null
  }
}

export const stopFetching = state => {
  return {
    ...state,
    fetching: false
  }
}

export const updateFilter = (state, { filter }) => {
  return {
    ...state,
    filter: { ...filter }
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_ERROR]: onError,
  [Types.ORGANIZATION_INVITES_REQUEST]: requestWithPaging,
  [Types.ORGANIZATION_INVITES_REQUEST_SUCCESS]: organizationInvitesRequestSuccess,
  [Types.INVITE_USER]: request,
  [Types.INVITE_USER_SUCCESS]: inviteUserSuccess,
  [Types.REMOVE_INVITE]: request,
  [Types.REMOVE_INVITE_SUCCESS]: removeInviteSuccess,
  [Types.ACCEPT_INVITE]: request,
  [Types.ACCEPT_INVITE_SUCCESS]: stopFetching,
  [Types.DECLINE_INVITE]: request,
  [Types.DECLINE_INVITE_SUCCESS]: stopFetching,
  [Types.UPDATE_FILTER]: updateFilter,
  [OrganizationsTypes.SET_ORGANIZATION_ID]: resetInvites
})
