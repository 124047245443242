import React from 'react'

export const Loader = () => {
  return (
    <div className="preloader">
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  )
}
