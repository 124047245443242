import { toast } from 'react-toastify'
import React from 'react'

export const showErrorResponse = response => {
  const { message } = response.data
  if (message) {
    showErrorToast(message)
  }
}

export const showErrorMessage = (action, title) => {
  showErrorToast(action, title)
}

export const showSuccessMessage = (action, title) => {
  showSuccessToast(action, title)
}

export const showInfoMessage = (action, title) => {
  showInfoToast(action, title)
}

export const clearNotification = () => {
  toast.dismiss()
}

const showErrorToast = (msg, title) => {
  toast(
    <div className="d-flex align-items-center">
      <i className="cui-circle-x icons font-2xl mr-2" />
      <div>
        {title && <span className="font-weight-bold d-block">{title}</span>}
        <span className="d-block">{msg}</span>
      </div>
    </div>,
    {
      autoClose: false,
      type: toast.TYPE.ERROR
    }
  )
}

const showSuccessToast = (msg, title) => {
  toast(
    <div className="d-flex align-items-center">
      <i className="cui-circle-check icons font-2xl mr-2" />
      <div>
        {title && <span className="font-weight-bold d-block">{title}</span>}
        <span className="d-block">{msg}</span>
      </div>
    </div>,
    {
      autoClose: 3000,
      type: toast.TYPE.SUCCESS
    }
  )
}

const showInfoToast = (msg, title) => {
  toast(
    <div className="d-flex align-items-center">
      <div>
        {title && <span className="font-weight-bold d-block">{title}</span>}
        <span className="d-block">{msg}</span>
      </div>
    </div>,
    {
      autoClose: 5000,
      type: toast.TYPE.INFO
    }
  )
}
