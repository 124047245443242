import { createReducer, createActions } from 'reduxsauce';
import { OrganizationsTypes } from './organizations';
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import {
    BEING_CALLED,
    BEING_FETCHED,
    FETCHED_SUCCESSFULLY,
    FETCHING_FAILED, LAST_CALL_SUCCEEDED, LAST_CALL_FAILED,
    NEVER_FETCHED, READY_TO_CALL, THEME_ORDER_BY_THEME_NAME,
    THEME_TYPE_SPIN_TO_WIN, THEME_TYPES, THEME_ORDER_BY_MODIFIED_ON
} from "../utils/const";

const { Types, Creators } = createActions(
    {
        requestThemes: null,
        indicateThemesFetchStart: null,
        onThemesRequestSuccess: ['themes', 'orgId'],
        onThemesRequestFailure: null,

        saveTheme: ['themeObj', 'imageFiles', 'additionalImagesGetter'],
        indicateThemeSaveStart: null,
        onThemeSaveSuccess: ['themeObj'],
        onThemeSaveFailure: null,

        deleteTheme: ['themeId', 'themeName'],
        indicateThemeDeleteStart: ['themeId'],
        onThemeDeleteSuccess: ['themeId'],
        onThemeDeleteFailure: null
    },
    {
        prefix: 'THEMES_'
    }
);

export const ThemeTypes = Types;

export default Creators;


/* ------------- Initial State ------------- */

/**
 * The initial state.
 *
 * Notes:
 *
 * - The type of themes being displayed is controller by URL
 *   and thus is handled by routing and not by this state.
 *   The themeType prop is required by the ThemesList component -
 *   it is extracted from routing and assigned by the ThemesListRouteHandler component.
 *
 * - Theme Ordering in the table is controlled by URL too.
 */
export const INITIAL_STATE = {

    // All themes of all types are fetched for a particular Organization Id.
    // When Organization Id gets changed, themes should be re-fetched.
    themes: null,

    themesFetchingState: NEVER_FETCHED,

    // Once themes are fetched, the Organization Id for which they were fetched
    // is recorded, so that we can later decide whether to re-fetch
    themesFetchedForOrgId: null,

    themeSaveState: READY_TO_CALL,

    themeDeleteState: READY_TO_CALL,
    themeIdBeingDeleted: null
};


/* ------------- Helpers ---------------*/

const updateThemesIfActual = (state, newFetchState, orgId = null, newThemesFetched = null) => {

    // Ignore if this particular fetching is not actual anymore
    if (state.themesFetchingState !== BEING_FETCHED) {
        return state;
    }

    return {
        ...state,
        themesFetchingState: newFetchState,
        themes: newThemesFetched,
        themesFetchedForOrgId: orgId
    }
};


/* ------------- Reducers ------------- */


// FETCHING THEMES LIST

export const indicateThemesFetchStart = (state) => {
    return {
        ...state,
        themesFetchingState: BEING_FETCHED,
        themes: null,
        themesFetchedForOrgId: null
    }
};

export const requestThemes = state => {
    return {
        ...state,
        themes: null,
        themesFetchingState: BEING_FETCHED,
        themesFetchedForOrgId: null,
    }
};

export const resetThemes = state => {
    return {
        ...state,
        themes: null,
        themesFetchingState: NEVER_FETCHED,
        themesFetchedForOrgId: null
    }
};


export const onThemesRequestSuccess = (state, { themes, orgId }) => {

    // Not sorting themes as the Theme Viewer will sort them anyway
    const newThemesFetched = themes.data;

    return updateThemesIfActual(state, FETCHED_SUCCESSFULLY, orgId, newThemesFetched);
};

export const onThemesRequestFailure = (state) => {
    return updateThemesIfActual(state, FETCHING_FAILED);
};


// SAVING THEMES


export const indicateThemeSaveStart = state => {
    return {
        ...state,
        themeSaveState: BEING_CALLED
    }
};

export const onThemeSaveSuccess = (state, {themeObj}) => {

    const idBeingSaved = themeObj.id;

    let {themes} = state;

    // Can be null if never fetched.
    // This is a rare case, but may happen if themes are being re-fetched
    // when a new theme is still being saved (e.g. when the user chooses a new Organization
    // from the dropdown menu).
    if (themes === null) {
        themes = [];
    }
    const existingThemeInd = _findIndex(themes, t => {return t.id === idBeingSaved});

    // Copy the themes array before modifying it
    let newThemes = themes.slice();


    if (existingThemeInd > -1) {

        // If a theme with this ID exists, replace the record with a new one
        newThemes[existingThemeInd] = themeObj;

    } else {

        // If new ID, append to the array
        newThemes.push(themeObj);
    }

    return {
        ...state,
        themeSaveState: LAST_CALL_SUCCEEDED,
        themes: newThemes
    }
};

export const onThemeSaveFailure = state => {
    return {
        ...state,
        themeSaveState: LAST_CALL_FAILED
    }
};


// DELETING THEMES

export const indicateThemeDeleteStart = (state, {themeId}) => {
    return {
        ...state,
        themeDeleteState: BEING_CALLED,
        themeIdBeingDeleted: themeId
    }
};

export const onThemeDeleteSuccess = (state, {themeId}) => {

    let {themes} = state;

    // Can be null if never fetched.
    // This is a rare case, but may happen if themes are being re-fetched
    // when a new theme is still being saved (e.g. when the user chooses a new Organization
    // from the dropdown menu).
    if (themes === null) {
        themes = [];
    }

    // Copy the themes array before modifying it
    let newThemes = themes.slice();
    _remove(newThemes, t => t.id === themeId);

    return {
        ...state,
        themeDeleteState: LAST_CALL_SUCCEEDED,
        themeIdBeingDeleted: null,
        themes: newThemes
    }
};

export const onThemeDeleteFailure = state => {
    return {
        ...state,
        themeDeleteState: LAST_CALL_FAILED,
        themeIdBeingDeleted: null
    }
};


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

    [Types.INDICATE_THEMES_FETCH_START]: indicateThemesFetchStart,
    [Types.REQUEST_THEMES]: requestThemes,
    [Types.ON_THEMES_REQUEST_SUCCESS]: onThemesRequestSuccess,
    [Types.ON_THEMES_REQUEST_FAILURE]: onThemesRequestFailure,

    // When Organization ID is updated, reset all themes immediately,
    // so that they are re-fetched from the server for the newly selected Organisation Id.
    [OrganizationsTypes.SET_ORGANIZATION_ID]: resetThemes,

    [Types.INDICATE_THEME_SAVE_START]: indicateThemeSaveStart,
    [Types.ON_THEME_SAVE_SUCCESS]: onThemeSaveSuccess,
    [Types.ON_THEME_SAVE_FAILURE]: onThemeSaveFailure,

    [Types.INDICATE_THEME_DELETE_START]: indicateThemeDeleteStart,
    [Types.ON_THEME_DELETE_SUCCESS]: onThemeDeleteSuccess,
    [Types.ON_THEME_DELETE_FAILURE]: onThemeDeleteFailure,

});
