import React, { Component } from 'react'
import './App.scss'
import DefaultLayout from './layouts/DefaultLayout'
import LoginLayout from './layouts/LoginLayout'
import { ToastContainer, Slide } from 'react-toastify'
import $ from 'jquery'
import { clearNotification } from './utils/notification-handler'

class App extends Component {
  componentDidMount() {
    $('body').click(() => {
      const toast = $('.Toastify__toast--error')
      if (toast && toast.is(':visible')) {
        clearNotification()
      }
    })
  }

  render() {
    const isLoggedIn = sessionStorage.getItem('auth_token')
    return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          newestOnTop={true}
          hideProgressBar={true}
          closeOnClick
          transition={Slide}
        />
        {isLoggedIn ? (
          <DefaultLayout {...this.props} />
        ) : (
          <LoginLayout {...this.props} />
        )}
      </React.Fragment>
    )
  }
}

export default App
