import { createReducer, createActions } from 'reduxsauce'
import { OrganizationsTypes } from './organizations'
import _ from 'lodash'

const { Types, Creators } = createActions(
  {
    requestPromotions: ['paging'],
    onPromotionsRequestSuccess: ['promotions'],
    deletePromotionRequest: ['id', 'name'],
    onDeletePromotionSuccess: ['id'],
    savePromotion: ['formData'],
    savePromotionSuccess: ['promotion'],
    getPromotionDetails: ['id', 'version'],
    onPromotionDetailsSuccess: ['data'],
    editPromotion: ['formData'],
    editPromotionSuccess: ['promotion', 'version'],
    publishPromotion: ['id', 'name', 'version'],
    onPublishPromotionSuccess: ['id', 'version'],
    resetPromotion: null,
    updateFilter: ['filter'],
    submitAmoeEntries: ['id', 'numOfEntries'],
    onAmoeEntriesSuccess: ['id'],
    submitAmoeWinner: ['id', 'data'],
    hideAmoeScreen: null,
    showAmoeScreen: ['promotion'],
    playAmoeGame: ['promotionId'],
    onPlayAmoeGameSuccess: null,
    getWinners: ['promotionId'],
    getWinnersSuccess: ['winners'],
    onError: null,
    downloadWinners: ['start', 'end', 'includePrevious'],
    downloadWinnersSuccess: ['winners']
  },
  {
    prefix: 'PROMOTIONS_'
  }
)

export const PromotionTypes = Types

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  data: [],
  winners: [],
  winnersFile: null,
  fetching: false,
  promotionDetails: null,
  redirectUrl: null,
  filter: {
    offset: 0,
    q: '',
    status: ''
  }
}

/* ------------- Reducers ------------- */

export const request = state => {
  return {
    ...state,
    winnersFile: null,
    fetching: true
  }
}

export const startPlayingGame = state => {
  return {
    ...state,
    isPlayingGame: true
  }
}

export const requestWithPaging = (state, { paging }) => {
  return {
    ...state,
    fetching: !paging, // if changing page, don't fetch but use isLoading
    isLoading: !!paging
  }
}

export const stopLoading = state => {
  return {
    ...state,
    fetching: false,
    amoeSubmitting: false,
    isPlayingGame: false
  }
}

export const onPromotionsRequestSuccess = (state, { promotions }) => {
  return {
    ...state,
    fetching: false,
    data: _.orderBy(promotions.data, 'modifiedOn', 'desc'),
    total: promotions.meta.total,
    isLoading: false
  }
}

export const resetPromotions = state => {
  return {
    ...state,
    data: null,
    promotionDetails: null
  }
}

export const onDeletePromotionSuccess = (state, { id }) => {
  return {
    ...state,
    fetching: false,
    data: state.data.map(x =>
      x.promotionId === id ? { ...x, status: 'Delete' } : x
    )
  }
}

export const savePromotionSuccess = (state, { promotion }) => {
  return {
    ...state,
    data: state.data ? [promotion, ...state.data] : null,
    fetching: false
  }
}

export const onPromotionDetailsSuccess = (state, { data }) => {
  return {
    ...state,
    fetching: false,
    promotionDetails: data
  }
}

export const editPromotionSuccess = (state, { promotion }) => {
  let promotions = null

  if (state.data) {
    const draftPromotionIndex = _.findIndex(
      state.data,
      p =>
        p.promotionId === promotion.promotionId &&
        p.version === promotion.version
    )

    if (draftPromotionIndex !== -1) {
      // Editing draft promotion
      promotions = [...state.data]
      promotions[draftPromotionIndex] = {
        ...promotion,
        status: 'Draft'
      }
    } else {
      // Editing published promotion
      const deletedPromotionIndex = _.findIndex(
        state.data,
        p => p.promotionId === promotion.promotionId
      )

      promotions = [...state.data]
      promotions[deletedPromotionIndex] = {
        ...promotions[deletedPromotionIndex],
        status: 'Delete'
      }
      promotions.splice(deletedPromotionIndex, 0, promotion)
    }
  }

  return {
    ...state,
    fetching: false,
    data: promotions,
    redirectUrl: null
  }
}

export const resetPromotionDetails = state => {
  return {
    ...state,
    promotionDetails: null
  }
}

export const onPromotionPublished = (state, { id, version }) => {
  return {
    ...state,
    fetching: false,
    data: state.data.map(x =>
      x.promotionId === id && x.version === version
        ? { ...x, status: 'Publish' }
        : x
    )
  }
}

export const updateFilter = (state, { filter }) => {
  return {
    ...state,
    filter: { ...filter }
  }
}

export const onAmoeEntriesSuccess = (state, { id }) => {
  const promotions = state.data.map(promotion => {
    if (promotion.promotionId === id) {
      promotion.amoeEntriesCreated = true
    }
    return promotion
  })
  return {
    ...state,
    data: promotions,
    amoeSubmitting: false,
    showAmoeForm: false,
    amoeItem: null
  }
}

export const submitAmoe = state => {
  return {
    ...state,
    amoeSubmitting: true
  }
}

export const hideAmoeScreen = state => {
  return {
    ...state,
    showAmoeForm: false,
    amoeItem: null
  }
}

export const showAmoeScreen = (state, { promotion }) => {
  return {
    ...state,
    showAmoeForm: true,
    amoeItem: promotion
  }
}

export const getWinnersSuccess = (state, { winners }) => {
  return {
    ...state,
    winners,
    fetching: false
  }
}

export const downloadWinnersSuccess = (state, { winners }) => {
  return {
    ...state,
    winnersFile: winners,
    fetching: false
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_PROMOTIONS]: requestWithPaging,
  [Types.ON_PROMOTIONS_REQUEST_SUCCESS]: onPromotionsRequestSuccess,
  [Types.DELETE_PROMOTION_REQUEST]: request,
  [Types.ON_DELETE_PROMOTION_SUCCESS]: onDeletePromotionSuccess,
  [Types.SAVE_PROMOTION]: request,
  [Types.SAVE_PROMOTION_SUCCESS]: savePromotionSuccess,
  [Types.GET_PROMOTION_DETAILS]: request,
  [Types.ON_PROMOTION_DETAILS_SUCCESS]: onPromotionDetailsSuccess,
  [Types.EDIT_PROMOTION]: request,
  [Types.EDIT_PROMOTION_SUCCESS]: editPromotionSuccess,
  [Types.RESET_PROMOTION]: resetPromotionDetails,
  [Types.PUBLISH_PROMOTION]: request,
  [Types.ON_PUBLISH_PROMOTION_SUCCESS]: onPromotionPublished,
  [Types.UPDATE_FILTER]: updateFilter,
  [Types.SUBMIT_AMOE_ENTRIES]: submitAmoe,
  [Types.ON_AMOE_ENTRIES_SUCCESS]: onAmoeEntriesSuccess,
  [Types.HIDE_AMOE_SCREEN]: hideAmoeScreen,
  [Types.SHOW_AMOE_SCREEN]: showAmoeScreen,
  [Types.SUBMIT_AMOE_WINNER]: submitAmoe,
  [Types.PLAY_AMOE_GAME]: startPlayingGame,
  [Types.ON_PLAY_AMOE_GAME_SUCCESS]: stopLoading,
  [Types.GET_WINNERS]: request,
  [Types.GET_WINNERS_SUCCESS]: getWinnersSuccess,
  [Types.ON_ERROR]: stopLoading,
  [OrganizationsTypes.SET_ORGANIZATION_ID]: resetPromotions,
  [Types.DOWNLOAD_WINNERS]: request,
  [Types.DOWNLOAD_WINNERS_SUCCESS]: downloadWinnersSuccess
})
