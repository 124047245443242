import React from 'react'
import {
  AddOrganization,
  EditOrganization,
  OrganizationList
} from './components/Organizations/'
import InvitationAccepted from '../login/components/InvitationAccepted'
import InvitationDeclined from '../login/components/InvitationDeclined'

const Home = React.lazy(() => import('./components/Home'))
const UserProfile = React.lazy(() => import('./components/Users/UserProfile'))
const InviteUser = React.lazy(() => import('./components/Users/InviteUser'))
const Users = React.lazy(() => import('./components/Users/Users'))
const AddPromotion = React.lazy(() =>
  import('./components/Promotions/AddPromotion')
)
const EditPromotion = React.lazy(() =>
  import('./components/Promotions/EditPromotion')
)
const Promotions = React.lazy(() => import('./components/Promotions'))
const PromotionDetails = React.lazy(() =>
  import('./components/Promotions/PromotionDetails')
)
const Winners = React.lazy(() => import('./components/Promotions/Winners'))

const Themes = React.lazy(() =>
  import('./components/Themes/ThemesList/ThemesListRouteHandler')
)
const AddTheme = React.lazy(() =>
  import('./components/Themes/EditTheme/AddThemeRouteHandler')
)
const EditTheme = React.lazy(() =>
  import('./components/Themes/EditTheme/EditThemeRouteHandler')
)

const Members = React.lazy(() => import('./components/Members'))
const MemberDetails = React.lazy(() =>
  import('./components/Members/MemberDetails')
)

const index = [
  { path: '/', exact: true, name: 'Home', component: Home },
  { path: '/home', name: 'Home', component: Home },
  { path: '/users', name: 'Users', component: Users },
  { path: '/adduser', name: 'Invite user', component: InviteUser },
  { path: '/user/:userId', name: 'User Profile', component: UserProfile },
  { path: '/user-profile', name: 'User Profile', component: UserProfile },
  {
    path: '/organizations',
    name: 'Organizations',
    component: OrganizationList
  },
  {
    path: '/addorganization',
    name: 'Add Organization',
    component: AddOrganization
  },
  {
    path: '/organization/:id?',
    name: 'Edit Organization',
    component: EditOrganization
  },
  {
    path: '/promotions',
    name: 'Promotions',
    component: Promotions
  },
  { path: '/addpromotion', name: 'Add Promotion', component: AddPromotion },
  {
    path: '/editpromotion/:id/:version',
    name: 'Edit Promotion',
    component: EditPromotion
  },
  {
    path: '/promotion/:id/:version',
    name: 'Promotion Details',
    component: PromotionDetails
  },
  {
    path: '/winners',
    name: 'Download Winners',
    component: Winners
  },
  {
    path: '/themes/:themeType?',
    name: 'Themes',
    component: Themes
  },
  {
    path: '/add-theme/:themeType?',
    name: 'Add Theme',
    component: AddTheme
  },
  {
    path: '/edit-theme/:id',
    name: 'Edit Theme',
    component: EditTheme
  },
  {
    path: '/invites/:token/accept',
    component: InvitationAccepted
  },
  {
    path: '/invites/:token/decline',
    component: InvitationDeclined
  },
  {
    path: '/members',
    name: 'Members',
    component: Members
  },
  {
    path: '/member/:id/details',
    name: 'Member Details',
    component: MemberDetails
  }
]

export default index
