import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Container
} from 'reactstrap'
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from '@coreui/react'
import logo from '../../assets/img/brand/rtm-logo.svg'

class DefaultHeader extends Component {
  render() {
    // eslint-disable-next-line
    const { username, organizations, currentOrganization } = this.props
    let organizationName = ''

    if (organizations) {
      const organization = organizations.find(
        org => org.id === currentOrganization
      )
      organizationName = organization ? organization.name : ''
    }

    return (
      <React.Fragment>
        <div className="navbar-wrapper clearfix">
          <div className="logo-wrapper">
            <AppNavbarBrand
              full={{
                src: logo,
                width: 141,
                height: 40,
                alt: 'RTM Logo'
              }}
            />
            <AppSidebarToggler className="d-lg-none" display="md" mobile />
          </div>
          <div className="navbar-container clearfix">
            <Container fluid>
              <div className="navbar-nav nav-left organization-switch">
                <span className="organization-name">{organizationName}</span>
              </div>
              <Nav className="nav-right" navbar>
                <AppHeaderDropdown direction="down">
                  <DropdownToggle nav>
                    <span>{username}</span>
                  </DropdownToggle>
                  <DropdownMenu
                  // right
                  // style={{ right: 'auto' }}
                  >
                    <DropdownItem tag={Link} to="/user-profile">
                      <i className="feather icon-user" /> Profile
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/settings">
                      <i className="feather icon-settings" /> Settings
                    </DropdownItem>
                    <DropdownItem onClick={e => this.props.onLogout(e)}>
                      <i className="feather icon-log-out" /> Logout
                    </DropdownItem>
                  </DropdownMenu>
                </AppHeaderDropdown>
              </Nav>
            </Container>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DefaultHeader
