import { createActions, createReducer } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    showConfirmation: ['confirmationText', 'callback'],
    closeConfirmation: null
  },
  {
    prefix: 'DIALOG_'
  }
)

export const ConfirmDialogTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  isOpen: false
}

/* ------------- Reducers ------------- */

export const showConfirmation = (state, action) => {
  return {
    ...state,
    isOpen: true,
    confirmationText: action.confirmationText,
    callback: action.callback
  }
}

export const closeConfirmation = state => {
  return {
    ...state,
    isOpen: false
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_CONFIRMATION]: showConfirmation,
  [Types.CLOSE_CONFIRMATION]: closeConfirmation
})
