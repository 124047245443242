import { all, takeLatest } from 'redux-saga/effects'

/* ------------- Types ------------- */
import { AuthTypes } from '../modules/auth'
import { CommonTypes } from '../modules/common'
import { UsersTypes } from '../modules/users'
import { OrganizationsTypes } from '../modules/organizations'
import { InvitesTypes } from '../modules/invites'
import { PromotionTypes } from '../modules/promotions'
import { ThemeTypes } from '../modules/themes'
import { MembersTypes } from '../modules/members'

/* ------------- Sagas ------------- */
import {
  login,
  forgetPassword,
  getMyUser,
  signup,
  resetPassword,
  editMyUser
} from './auth'
import { navigateTo } from './common'
import {
  organizationUsersRequest,
  getUser,
  disableUser,
  enableUser,
  changeUserRole
} from './users'
import {
  organizationsRequest,
  organizationSaveRequest,
  organizationDeleteRequest,
  editOrganization,
  getSubOrgs
} from './organizations'
import {
  organizationInvitesRequest,
  removeInvite,
  resendInvite,
  inviteUser,
  acceptInvite,
  declineInvite
} from './invites'
import {
  deletePromotion,
  getPromotionDetails,
  requestPromotions,
  savePromotion,
  editPromotion,
  publishPromotion,
  submitAmoeWinner,
  submitAmoeEntries,
  playAmoeGame,
  getWinners,
  downloadWinners
} from './promotions'
import { organizationMembersRequest, getMemberDetails } from './members'
import { sagaDeleteTheme, sagaRequestThemes, sagaSaveTheme } from './themes'

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(OrganizationsTypes.ORGANIZATIONS_REQUEST, organizationsRequest),
    takeLatest(
      OrganizationsTypes.ORGANIZATION_SAVE_REQUEST,
      organizationSaveRequest
    ),
    takeLatest(
      OrganizationsTypes.ORGANIZATION_DELETE_REQUEST,
      organizationDeleteRequest
    ),
    takeLatest(OrganizationsTypes.EDIT_ORGANIZATION, editOrganization),
    takeLatest(OrganizationsTypes.GET_SUB_ORGS, getSubOrgs),
    // takeLatest(AuthTypes.LOGOUT_REQUEST, logout),
    takeLatest(AuthTypes.FORGET_PASSWORD_REQUEST, forgetPassword),
    takeLatest(AuthTypes.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(AuthTypes.GET_MY_USER, getMyUser),
    takeLatest(AuthTypes.EDIT_MY_USER, editMyUser),
    takeLatest(AuthTypes.SIGNUP_REQUEST, signup),
    takeLatest(CommonTypes.NAVIGATE_TO, navigateTo),
    takeLatest(UsersTypes.ORGANIZATION_USERS_REQUEST, organizationUsersRequest),
    takeLatest(UsersTypes.GET_USER, getUser),
    takeLatest(UsersTypes.DISABLE_USER, disableUser),
    takeLatest(UsersTypes.ENABLE_USER, enableUser),
    takeLatest(UsersTypes.CHANGE_USER_ROLE, changeUserRole),
    takeLatest(
      InvitesTypes.ORGANIZATION_INVITES_REQUEST,
      organizationInvitesRequest
    ),
    takeLatest(InvitesTypes.INVITE_USER, inviteUser),
    takeLatest(InvitesTypes.REMOVE_INVITE, removeInvite),
    takeLatest(InvitesTypes.RESEND_INVITE, resendInvite),
    takeLatest(InvitesTypes.ACCEPT_INVITE, acceptInvite),
    takeLatest(InvitesTypes.DECLINE_INVITE, declineInvite),
    takeLatest(PromotionTypes.REQUEST_PROMOTIONS, requestPromotions),
    takeLatest(PromotionTypes.DELETE_PROMOTION_REQUEST, deletePromotion),
    takeLatest(PromotionTypes.SAVE_PROMOTION, savePromotion),
    takeLatest(PromotionTypes.GET_PROMOTION_DETAILS, getPromotionDetails),
    takeLatest(PromotionTypes.EDIT_PROMOTION, editPromotion),
    takeLatest(PromotionTypes.PUBLISH_PROMOTION, publishPromotion),
    takeLatest(PromotionTypes.SUBMIT_AMOE_ENTRIES, submitAmoeEntries),
    takeLatest(PromotionTypes.SUBMIT_AMOE_WINNER, submitAmoeWinner),
    takeLatest(PromotionTypes.PLAY_AMOE_GAME, playAmoeGame),
    takeLatest(PromotionTypes.GET_WINNERS, getWinners),
    takeLatest(PromotionTypes.DOWNLOAD_WINNERS, downloadWinners),
    takeLatest(
      MembersTypes.ORGANIZATION_MEMBERS_REQUEST,
      organizationMembersRequest
    ),
    takeLatest(MembersTypes.GET_MEMBER_DETAILS, getMemberDetails),

    // When Organization Id changes, themes must be fetched immediately
    // as they can be used in multiple scenarios: creating promotions,
    // creating / viewing themes / etc
    takeLatest(OrganizationsTypes.SET_ORGANIZATION_ID, sagaRequestThemes),

    // Themes
    takeLatest(ThemeTypes.SAVE_THEME, sagaSaveTheme),
    takeLatest(ThemeTypes.DELETE_THEME, sagaDeleteTheme)
  ])
}
