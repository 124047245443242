import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

export const Select = ({
  input,
  required,
  disabled,
  label,
  options,
  meta: { touched, error }
}) => {
  return (
    <FormGroup>
      {label && <Label htmlFor={input.name}>{label}</Label>}
      <Input
        {...input}
        type={'select'}
        id={input.name}
        className={touched && error ? 'is-invalid' : ''}
        disabled={disabled}
        required={required}
      >
        {options.map(x => (
          <option key={x.key} value={x.key}>
            {x.label}
          </option>
        ))}
      </Input>
      {showErrorLabel(touched, error)}
    </FormGroup>
  )
}

function showErrorLabel(touched, error) {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>
  }
  return null
}
