import { createActions, createReducer } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    navigateTo: ['route'],
    showModal: ['content'],
    closeModal: null
  },
  {
    prefix: 'COMMON_'
  }
)

export const CommonTypes = Types

export default Creators

export const INITIAL_STATE = {
  isModalOpen: false,
  modalContent: null
}

export const showModal = (state, { content }) => {
  return {
    ...state,
    isModalOpen: true,
    modalContent: content
  }
}

export const closeModal = state => {
  return {
    ...state,
    isModalOpen: false,
    modalContent: null
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_MODAL]: showModal,
  [Types.CLOSE_MODAL]: closeModal
})
