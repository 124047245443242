import { call, put, select } from 'redux-saga/effects'
import OrganizationsActions from '../modules/organizations'
import InvitesActions from '../modules/invites'
import CommonActions from '../modules/common'
import {
  showErrorResponse,
  showSuccessMessage
} from '../utils/notification-handler'

import apiServices from '../api-services'
import { callApi, isOk } from './helper'

export function* organizationInvitesRequest({ paging }) {
  if (paging) {
    yield put(InvitesActions.updateFilter(paging))
  }
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(
    apiServices.getOrganizationInvites,
    orgId,
    paging
  )

  if (isOk(response)) {
    yield put(InvitesActions.organizationInvitesRequestSuccess(response.data))
  } else {
    yield call(errorHandler, response)
  }
}

export function* inviteUser({ formData }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.inviteUser, orgId, formData)

  if (isOk(response)) {
    yield put(InvitesActions.inviteUserSuccess(response.data))
    yield put(CommonActions.navigateTo('/users/invites-list'))
    showSuccessMessage('Your invitations is sent.')
  } else {
    yield call(errorHandler, response)
  }
}

export function* removeInvite({ inviteId }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.removeInvite, orgId, inviteId)

  if (isOk(response)) {
    yield put(InvitesActions.removeInviteSuccess(inviteId))
    showSuccessMessage(`You've successfully removed an invitation.`)
  } else {
    yield call(errorHandler, response)
  }
}

export function* resendInvite({ inviteId }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.resendInvite, orgId, inviteId)

  if (isOk(response)) {
    showSuccessMessage(`You've successfully resent an invitation.`)
  } else {
    yield call(errorHandler, response)
  }
}

export function* acceptInvite({ token }) {
  const isLoggedIn = sessionStorage.getItem('auth_token')
  if (isLoggedIn) {
    let response = yield callApi(apiServices.acceptInvite, token)
    localStorage.removeItem('invitation_token')
    if (isOk(response)) {
      // replace auth-token as user orgs have been changed - therefore new access token is generated
      sessionStorage.setItem('auth_token', response.data.token)
      yield put(OrganizationsActions.organizationsRequest())
      showSuccessMessage(`You've successfully accepted invitation.`)
    } else {
      yield call(errorHandler, response)
    }
  } else {
    localStorage.setItem('invitation_token', token)
    yield put(CommonActions.navigateTo('/login'))
  }
}

export function* declineInvite({ token }) {
  const response = yield callApi(apiServices.declineInvite, token)
  localStorage.removeItem('invitation_token')

  if (isOk(response)) {
    showSuccessMessage(`You've declined invitation.`)
    yield put(CommonActions.navigateTo('/home'))
  } else {
    yield call(errorHandler, response)
  }
}

function* errorHandler(response) {
  if (response.data) {
    showErrorResponse(response)
  }
  yield put(InvitesActions.onError())
}
