import React, { Component } from 'react'
import { Field } from 'redux-form'
import { Input, Select } from '../../../../components/common'
import { Button, CardBody, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import _ from 'lodash'

class OrganizationForm extends Component {
  render() {
    const { onSubmit, submitting, formName, label } = this.props
    return (
      <CardBody>
        <form id={formName} noValidate onSubmit={onSubmit}>
          <h1>Organization</h1>
          <p className="text-muted">{label}</p>
          <Field
            name="name"
            type="input"
            required
            disabled={submitting}
            placeholder="Name"
            className="mb-3"
            groupText={<i className="feather icon-user" />}
            component={Input}
          />
          <Field
            name="memberProgram"
            type="input"
            required
            disabled={submitting}
            placeholder="Member Program"
            className="mb-3"
            groupText={<i className="feather icon-book" />}
            component={Input}
          />
          {this.renderParentInput()}
        </form>
        <Row>
          <Col xs="12">
            <Button
              color="primary"
              type="submit"
              form={formName}
              className="px-4 mr-2"
              disabled={submitting}
            >
              Save
            </Button>
            <Link to={`/organizations`}>
              <Button color="secondary" className="px-4" disabled={submitting}>
                Back
              </Button>
            </Link>
          </Col>
        </Row>
      </CardBody>
    )
  }

  renderParentInput = () => {
    const { editingItem, organizations, submitting, currentOrgId } = this.props
    let list = []
    if (editingItem) {
      if (editingItem.parentId && editingItem.id !== currentOrgId) {
        list = _.filter(organizations, x => x.id !== editingItem.id)
      } else {
        return null
      }
    } else {
      list = organizations
    }

    if (list && list.length > 0) {
      return (
        <Field
          name="parentId"
          disabled={submitting}
          label="Parent Organization"
          required
          component={Select}
          options={[
            { key: '', label: 'Choose...' },
            ...list.map(x => ({
              key: x.id,
              label: x.name
            }))
          ]}
        />
      )
    }
  }
}

export default OrganizationForm
