import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table, Row, Col, InputGroup } from 'reactstrap'
import ConfirmationDialog from '../../../../components/common/ConfirmationDialog'
import { Loader, Pagination, Preloader } from '../../../../components/common'
import { getFormattedDate, pageSize } from '../../../../utils/util-helper'

export default class OrganizationList extends Component {
  constructor(props) {
    super(props)
    const {
      filter: { offset, status, q }
    } = this.props
    this.state = {
      activePage: Math.floor(offset / pageSize + 1),
      activeStatus: status,
      search: q
    }
  }

  componentDidMount() {
    const { fetching, currentOrgId, organizations } = this.props
    if (organizations === null && !fetching && currentOrgId) {
      this.props.getSubOrgs(currentOrgId)
    }
  }

  componentDidUpdate(prevProps) {
    const { currentOrgId } = this.props
    if (prevProps.currentOrgId && prevProps.currentOrgId !== currentOrgId) {
      this.props.getSubOrgs(currentOrgId)
    }
  }

  canAddNewOrganization = () => {
    const { isAdmin, myOrganizations } = this.props
    return isAdmin || (myOrganizations && myOrganizations.length === 0)
  }

  /*eslint-disable complexity*/
  render() {
    const { fetching, organizations, isAdmin, total, isLoading } = this.props
    if (fetching || organizations === null) {
      return <Preloader />
    }

    const { activePage, search } = this.state
    return (
      <div>
        <div className="page-header">
          <Row className="align-items-center">
            <Col md="8">
              <div className="page-header-title">
                <h4>Organizations</h4>
                <span>
                  Here's the list of all organizations you have access to:
                </span>
              </div>
            </Col>
            {this.canAddNewOrganization() && (
              <Col md="4">
                <div className="add-new-item">
                  <Button
                    color="inverse"
                    onClick={() => this.props.navigateTo('/addorganization')}
                  >
                    <i className="feather icon-plus-circle" />
                    Add New Organization
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </div>

        <div className="table-header">
          <Row className="align-items-center">
            <Col md="8" sm="8">
              <ul className="table-top-nav">
                <li className="all">{this.renderStatusLink('', 'All')}</li>
                {/*<li className="active">{this.renderStatusLink(1, 'Active')}</li>
                <li className="delete">
                  {this.renderStatusLink(2, 'Deleted')}
                </li>*/}
              </ul>
            </Col>
            <Col md="4" sm="4">
              <div className="table-search">
                <form name="search-org">
                  <InputGroup className="input-group-button right-button">
                    <input
                      type="search"
                      id="search-org-input"
                      name="search-org-input"
                      className="form-control"
                      placeholder="Search Organizations"
                      value={search}
                      onChange={e =>
                        this.setState({
                          search: e.currentTarget.value
                        })
                      }
                    />

                    <div className="input-group-append">
                      <button
                        className="input-group-text"
                        onClick={e => {
                          e.preventDefault()
                          this.filterItems()
                        }}
                        type="submit"
                      >
                        <i className="feather icon-search" />
                      </button>
                    </div>
                  </InputGroup>
                </form>
              </div>
            </Col>
          </Row>
        </div>

        <div className="card">
          <div className="card-block">
            {isLoading ? (
              <div className="w-100 text-center">
                <Loader />
              </div>
            ) : (
              <Table
                responsive
                striped
                bordered
                className="nowrap main-list-table"
              >
                <thead>
                  <tr>
                    <th className="name-col">Name</th>
                    <th className="parent-col">Parent Organization</th>
                    {/*<th className="author-col">Status</th>*/}
                    <th className="date-col">Date</th>
                    {isAdmin && (
                      <th className="actions-col text-center">Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {organizations.map(x => (
                    <tr key={x.id}>
                      <td className="name-col">
                        {isAdmin ? (
                          <Link
                            className="manage action-link"
                            to={`/organization/${x.id}`}
                          >
                            {x.name}
                          </Link>
                        ) : (
                          <span className="f-w-600">{x.name}</span>
                        )}
                      </td>
                      <td className="parent-col">
                        {this.getParentName(x.parentId)}
                      </td>
                      {/*<td className="author-col">
                        {renderOrganizationStatus(x.status)}
                      </td>*/}
                      <td className="date-col">
                        {getFormattedDate(x.modifiedOn)}
                      </td>
                      {isAdmin && (
                        <td className="actions-col text-center">
                          <div className="org-actions">
                            <Link
                              className="manage action-link"
                              to={`/organization/${x.id}`}
                            >
                              <i className="feather icon-edit" />
                              Edit
                            </Link>

                            <span
                              className="link trash action-link"
                              onClick={() => this.deleteOrganization(x)}
                            >
                              <i className="feather icon-trash-2" />
                              Delete
                            </span>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
          <div className="card-footer table-footer">
            <div className="table-actions">
              <Row className="align-items-center">
                <Col sm="6" className="ml-sm-auto ml-auto">
                  <Pagination
                    handlePageChange={page => this.changePage(page)}
                    activePage={activePage}
                    totalItems={total}
                    disabled={isLoading}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ConfirmationDialog />
      </div>
    )
  }
  /*eslint-enable complexity*/

  changePage = page => {
    if (this.state.activePage !== page) {
      this.setState(
        {
          activePage: page
        },
        () => this.filterItems()
      )
    }
  }

  filterItems = () => {
    const { activePage, activeStatus, search } = this.state
    let paging = {
      offset: (activePage - 1) * pageSize,
      limit: pageSize
    }
    if (activeStatus) {
      paging.status = activeStatus
    }
    if (search) {
      paging.q = search
    }
    this.props.getSubOrgs(this.props.currentOrgId, paging)
  }

  renderStatusLink = (status, label) => {
    const { activeStatus } = this.state
    const { total, isLoading } = this.props
    return (
      <a
        href="#/"
        className={activeStatus === status ? 'current' : ''}
        onClick={() => this.filterByStatus(status)}
      >
        {label}{' '}
        {activeStatus === status && !isLoading && (
          <span className="count">({total})</span>
        )}
      </a>
    )
  }

  filterByStatus = status => {
    this.setState({ activeStatus: status, activePage: 1 }, () =>
      this.filterItems()
    )
  }

  deleteOrganization = org => {
    const {
      showConfirmation,
      closeConfirmation,
      organizationDeleteRequest
    } = this.props
    showConfirmation(
      `Do you really want to delete organization "${org.name}"`,
      confirmed => {
        closeConfirmation()
        if (confirmed) {
          organizationDeleteRequest(org)
        }
      }
    )
  }

  getParentName = parentId => {
    const parent = this.props.allOrgs.find(x => x.id === parentId)
    if (parent) {
      return parent.name
    }
    return 'N/A'
  }
}
