import { Component } from 'react'
import { connect } from 'react-redux'
import AuthActions from '../../modules/auth'

class AuthenticatedUser extends Component {
  componentDidMount() {
    const { userId } = this.props
    if (!userId) {
      this.props.getMyUser(true)
    }
  }

  render() {
    return this.props.children
  }
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId
  }
}

export default connect(
  mapStateToProps,
  { ...AuthActions }
)(AuthenticatedUser)
