import React, { Component } from 'react'
import { FormGroup, Label } from 'reactstrap'
import ReactDatePicker from 'react-datepicker'

export class DatePicker extends Component {
  componentDidUpdate = prevProps => {
    const { minDate, input } = this.props

    if (minDate && minDate !== prevProps.minDate && minDate > input.value) {
      this.handleChange(null)
    }
  }

  handleChange = date => {
    this.props.input.onChange(date)
  }

  render() {
    const {
      placeholder,
      label,
      disabled,
      showTime,
      input,
      minDate,
      dateFormat = 'MM/dd/yyyy h:mm aa',
      meta: { error, touched }
    } = this.props

    return (
      <FormGroup className={touched && error ? 'is-invalid' : ''}>
        <Label>{label}</Label>
        <ReactDatePicker
          selected={input.value !== '' ? input.value : null}
          onChange={this.handleChange}
          className={
            touched && error ? 'form-control is-invalid' : 'form-control'
          }
          placeholderText={placeholder}
          dateFormat={dateFormat}
          disabled={disabled}
          showTimeSelect={showTime}
          minDate={minDate !== '' ? minDate : null}
        />
        {this.showErrorLabel(touched, error)}
      </FormGroup>
    )
  }

  showErrorLabel = (touched, error) => {
    if (touched && error) {
      return <div className="invalid-feedback">{error}</div>
    }
    return null
  }
}

export default DatePicker
