import { connect } from 'react-redux'
import ForgotPassword from './ForgotPassword'
import AuthActions from '../../../../modules/auth'

const mapStateToProps = state => {
  return {
    fetching: state.auth.fetching
  }
}

export default connect(
  mapStateToProps,
  {
    ...AuthActions
  }
)(ForgotPassword)
