import React, { Component } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

export class FileInput extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    const {
      input: { onChange }
    } = this.props
    onChange(e.target.files[0])
  }

  showErrorLabel = (touched, error) => {
    if (touched && error) {
      return <div className="invalid-feedback">{error}</div>
    }
    return null
  }

  render() {
    const {
      input,
      label,
      required,
      disabled,
      meta: { touched, error }
    } = this.props
    return (
      <FormGroup>
        <Label for={input.name}>{label}</Label>
        <Input
          type="file"
          id={input.name}
          className={touched && error ? 'is-invalid' : ''}
          required={required}
          disabled={disabled}
          accept=".jpg, .png, .jpeg"
          onChange={this.onChange}
        />
        {this.showErrorLabel(touched, error)}
      </FormGroup>
    )
  }
}
