export const NavAdmin = {
  items: [
    {
      title: true,
      name: 'Navigation',
      wrapper: {
        // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming
      },
      class: '' // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Dashboard',
      icon: 'feather icon-home',
      children: [
        {
          name: 'Home',
          url: '/home',
          icon: 'fa fa-angle-right'
        },
        {
          name: 'Reports',
          url: '/reports',
          icon: 'fa fa-angle-right'
        }
      ]
    },
    {
      name: 'Organizations',
      icon: 'feather icon-layers',
      children: [
        {
          name: 'All Organizations',
          url: '/organizations',
          icon: 'fa fa-angle-right'
        },
        {
          name: 'Add New',
          url: '/addorganization',
          icon: 'fa fa-angle-right'
        }
      ]
    },
    {
      name: 'Promotions',
      icon: 'feather icon-hash',
      children: [
        {
          name: 'All Promotions',
          url: '/promotions',
          icon: 'fa fa-angle-right'
        },
        {
          name: 'Add New',
          url: '/addpromotion',
          icon: 'fa fa-angle-right'
        },
        {
          name: 'Winners',
          url: '/winners',
          icon: 'fa fa-angle-right'
        }
      ]
    },
    {
      name: 'Themes',
      icon: 'fa fa-paint-brush',
      children: [
        {
          name: 'All Themes',
          url: '/themes',
          icon: 'fa fa-angle-right'
        },
        {
          name: 'Add New',
          url: '/add-theme',
          icon: 'fa fa-angle-right'
        }
      ]
    },
    // {
    //     divider: true,
    //     class: 'mt-2'
    // },
    {
      title: true,
      name: 'Organizations',
      wrapper: {
        // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming
      },
      class: '' // optional class names space delimited list for title item ex: "text-center"
    },

    {
      name: 'Users',
      icon: 'feather icon-users',
      children: [
        {
          name: 'All Users',
          url: '/users',
          icon: 'fa fa-angle-right'
        },
        {
          name: 'Add New',
          url: '/adduser',
          icon: 'fa fa-angle-right'
        }
      ]
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'feather icon-settings'
    },
    // {
    //   divider: true,
    //   class: 'mt-2'
    // },
    {
      title: true,
      name: 'Promotion',
      wrapper: {
        // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming
      },
      class: '' // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Members',
      url: '/members',
      icon: 'feather icon-user-check'
    }
  ]
}

export const navAnalyst = canAddOrg => {
  return {
    items: [
      {
        title: true,
        name: 'Navigation',
        wrapper: {
          // optional wrapper object
          element: '', // required valid HTML5 element tag
          attributes: {} // optional valid JS object with JS API naming
        },
        class: '' // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: 'Dashboard',
        icon: 'feather icon-home',
        children: [
          {
            name: 'Home',
            url: '/home',
            icon: 'fa fa-angle-right'
          },
          {
            name: 'Reports',
            url: '/reports',
            icon: 'fa fa-angle-right'
          }
        ]
      },
      {
        name: 'Organizations',
        icon: 'feather icon-layers',
        children: canAddOrg
          ? [
              {
                name: 'All Organizations',
                url: '/organizations',
                icon: 'fa fa-angle-right'
              },
              {
                name: 'Add New',
                url: '/addorganization',
                icon: 'fa fa-angle-right'
              }
            ]
          : [
              {
                name: 'All Organizations',
                url: '/organizations',
                icon: 'fa fa-angle-right'
              }
            ]
      },
      {
        name: 'Promotions',
        icon: 'feather icon-hash',
        children: [
          {
            name: 'All Promotions',
            url: '/promotions',
            icon: 'fa fa-angle-right'
          }
        ]
      },
      {
        name: 'Themes',
        icon: 'fa fa-paint-brush',
        children: [
          {
            name: 'All Themes',
            url: '/themes',
            icon: 'fa fa-angle-right'
          }
        ]
      },

      // {
      //     divider: true,
      //     class: 'mt-2'
      // },
      {
        title: true,
        name: 'Organizations',
        wrapper: {
          // optional wrapper object
          element: '', // required valid HTML5 element tag
          attributes: {} // optional valid JS object with JS API naming
        },
        class: '' // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: 'Users',
        icon: 'feather icon-users',
        children: [
          {
            name: 'All Users',
            url: '/users',
            icon: 'fa fa-angle-right'
          }
        ]
      },
      {
        name: 'Settings',
        url: '/settings',
        icon: 'feather icon-settings'
      },
      // {
      //   divider: true,
      //   class: 'mt-2'
      // },
      {
        title: true,
        name: 'Promotion',
        wrapper: {
          // optional wrapper object
          element: '', // required valid HTML5 element tag
          attributes: {} // optional valid JS object with JS API naming
        },
        class: '' // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: 'Members',
        url: '/members',
        icon: 'feather icon-user-check'
      }
    ]
  }
}
