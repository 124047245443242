import React from 'react'
import { FormGroup, Label, Input as ReactInput } from 'reactstrap'

export const Radio = ({
    input,
    disabled,
    label,
    index
}) => {
    return (
        <FormGroup className="mb-3" check>
            <Label check>
                <ReactInput
                    {...input}
                    disabled={disabled}
                    type="radio"
                    checked={input.value === index}
                    name={input.name}
                />
                {label}
            </Label>
        </FormGroup>
    )
}