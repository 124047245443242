import moment from 'moment'

const dateTimeOptions = {
  year: 'numeric',
  month: '2-digit',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
}

export const getFormattedDate = datetime => {
  if (datetime) {
    var date = new Date(datetime),
      options = {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric'
      }
    return date.toLocaleDateString('en-US', options)
  }
}

export const getPromotionFormattedDate = (datetime, timezone) => {
  if (datetime) {
    var date = new Date(datetime)
    setTimeToSelectedTimeZone(date, timezone, true)
    return `${date.toLocaleDateString(
      'en-US',
      dateTimeOptions
    )} ${getTimezoneAbbreviation(timezone)}`
  }
}

export const getLocalPromotionDate = (datetime, showTimezone = false) => {
  if (datetime) {
    return moment
      .utc(datetime)
      .local()
      .toDate()
      .toLocaleDateString(
        'en-US',
        showTimezone
          ? {
              ...dateTimeOptions,
              timeZoneName: 'short'
            }
          : dateTimeOptions
      )
  }
}

export const setTimeToSelectedTimeZone = (
  datetime,
  timezone,
  revert = false
) => {
  // Get datetime timezone (1) - getTimezoneOffset()
  const a = datetime.getTimezoneOffset()
  // Get selected timezone offset (2)
  let b
  switch (timezone) {
    case 'Eastern Standard Time (EST)':
      b = 300
      break
    case 'Central Standard Time (CST)':
      b = 360
      break
    case 'Mountain Standard Time (MST)':
      b = 420
      break
    case 'Pacific Standard Time (PST)':
      b = 480
      break
    case 'Alaskan Standard Time (AKST)':
      b = 540
      break
    default:
      b = 600
      break
  }

  // Add delta Math.abs(a - b)
  if (revert) {
    datetime.setMinutes(datetime.getMinutes() - Math.abs(a - b))
  } else {
    datetime.setMinutes(datetime.getMinutes() + Math.abs(a - b))
  }
}

export const getTimezoneAbbreviation = name => {
  switch (name) {
    case 'Eastern Standard Time (EST)':
      return 'EST'
    case 'Central Standard Time (CST)':
      return 'CST'
    case 'Mountain Standard Time (MST)':
      return 'MST'
    case 'Pacific Standard Time (PST)':
      return 'PST'
    case 'Alaskan Standard Time (AKST)':
      return 'AKST'
    default:
      return 'N/A'
  }
}

export const pageSize = 10
