import { connect } from 'react-redux'
import Login from './Login'
import AuthActions from '../../../../modules/auth'

const mapStateToProps = state => {
  return {
    fetching: state.auth.fetching,
    errorMessage: state.auth.error
  }
}

export default connect(
  mapStateToProps,
  { ...AuthActions }
)(Login)
