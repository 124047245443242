import React, { Component } from 'react'
import { Card, CardGroup, Col, Row } from 'reactstrap'
import { Preloader } from '../../../../components/common'
import { requiredField } from '../../../../utils/form-validator'
import { reduxForm } from 'redux-form'
import OrganizationForm from './OrganizationForm'

class AddOrganization extends Component {
  handleFormSubmit = formProps => {
    return this.props.organizationSaveRequest(formProps)
  }

  shouldRedirectToList = () => {
    const { isAdmin, myOrganizations } = this.props
    return isAdmin === false && myOrganizations && myOrganizations.length > 0
  }

  componentDidUpdate(prevProps) {
    const { isAdmin, currentOrgId } = this.props

    if ((prevProps.isAdmin && !isAdmin) || this.shouldRedirectToList()) {
      this.props.navigateTo('/organizations')
      return
    }

    if (prevProps.currentOrgId && prevProps.currentOrgId !== currentOrgId) {
      this.props.getSubOrgs(currentOrgId)
    }
  }

  componentWillMount() {
    if (this.shouldRedirectToList()) {
      this.props.navigateTo('/organizations')
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      fetching,
      organizations,
      currentOrgId
    } = this.props
    if (fetching) {
      return <Preloader />
    }
    return (
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <CardGroup>
            <Card className="p-4">
              <OrganizationForm
                onSubmit={handleSubmit(this.handleFormSubmit)}
                formName="addOrgForm"
                label="Add new organization"
                organizations={organizations}
                submitting={submitting}
                currentOrgId={currentOrgId}
              />
            </Card>
          </CardGroup>
        </Col>
      </Row>
    )
  }
}

const validate = values => {
  const errors = {}
  requiredField(values, errors, 'name')
  requiredField(values, errors, 'parentId')
  return errors
}

export default reduxForm({
  validate,
  form: 'addOrgForm'
})(AddOrganization)
