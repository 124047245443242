import { createReducer, createActions } from 'reduxsauce'
import { OrganizationsTypes } from './organizations'
import _ from 'lodash'

const { Types, Creators } = createActions(
  {
    loginRequest: ['email', 'password'],
    loginSuccess: ['data'],
    signupRequest: ['payload'],
    forgetPasswordRequest: ['email'],
    forgetPasswordSuccess: null,
    resetPasswordRequest: ['payload'],
    resetPasswordSuccess: null,
    logout: null,
    getMyUser: ['initial'],
    getMyUserSuccess: ['data'],
    editMyUser: ['formProps'],
    editMyUserSuccess: ['name'],
    onError: ['error']
  },
  {
    prefix: 'AUTH_'
  }
)

export const AuthTypes = Types

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  fetching: false,
  organizations: null,
  error: null
}

/* ------------- Reducers ------------- */

export const request = state => {
  return {
    ...state,
    fetching: true,
    error: null
  }
}

export const loginSuccess = (state, { data }) => {
  const { authToken, refreshToken } = data.token
  sessionStorage.removeItem('org_id')
  sessionStorage.setItem('auth_token', authToken)
  sessionStorage.setItem('refresh_token', refreshToken)
  return {
    ...state,
    ...data.user,
    fetching: false
  }
}

export const getMyUserSuccess = (state, { data }) => {
  return {
    ...state,
    fetching: false,
    ...{
      ...data,
      organizations: _.uniqBy(data.organizations, 'organizationId')
    }
  }
}

export const editMyUserSuccess = (state, { name }) => {
  return {
    ...state,
    fetching: false,
    name
  }
}

export const logoutSuccess = () => {
  sessionStorage.removeItem('auth_token')
  sessionStorage.removeItem('org_id')
  return INITIAL_STATE
}

export const stopFetching = state => {
  return {
    ...state,
    fetching: false
  }
}

export const onError = (state, action) => {
  return {
    ...state,
    fetching: false,
    error: action.error
  }
}

export const setIsAdmin = (state, { orgId }) => {
  return {
    ...state,
    isAdmin:
      state.organizations &&
      !!state.organizations.find(
        x =>
          x.status === 'Active' &&
          x.organizationId === orgId &&
          x.role === 'Administrator'
      )
  }
}

export const removeMyOrg = (state, { id }) => {
  const myOrgs = _.filter(state.organizations, x => x.organizationId !== id)
  return {
    ...state,
    isAdmin: myOrgs.length > 0 ? state.isAdmin : false,
    organizations: myOrgs
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.SIGNUP_REQUEST]: request,
  [Types.LOGOUT]: logoutSuccess,
  [Types.FORGET_PASSWORD_REQUEST]: request,
  [Types.FORGET_PASSWORD_SUCCESS]: stopFetching,
  [Types.RESET_PASSWORD_REQUEST]: request,
  [Types.RESET_PASSWORD_SUCCESS]: stopFetching,
  [Types.GET_MY_USER]: request,
  [Types.GET_MY_USER_SUCCESS]: getMyUserSuccess,
  [Types.EDIT_MY_USER]: request,
  [Types.EDIT_MY_USER_SUCCESS]: editMyUserSuccess,
  [OrganizationsTypes.SET_ORGANIZATION_ID]: setIsAdmin,
  [OrganizationsTypes.ORGANIZATION_DELETE_REQUEST_SUCCESS]: removeMyOrg,
  [Types.ON_ERROR]: onError
})
