import {put, select, call} from 'redux-saga/effects'
import PromotionActions from "../modules/promotions";
import ThemeActions from "../modules/themes";
import {callApi, isOk} from "./helper";
import apiServices from "../api-services";
import {showErrorResponse, showSuccessMessage} from "../utils/notification-handler";
import CommonActions from "../modules/common";
import {getThemesUrl} from "../utils/routes-helper";
import {THEME_ORDER_BY_MODIFIED_ON} from "../utils/const";
import _keys from "lodash/keys";
import _defaultsDeep from "lodash/defaultsDeep";
import flatten from 'flatley';

export function* sagaRequestThemes() {

    const {
        organizations: {
            selectedOrganizationId
        }
    } = yield select(state => state);

    // If no Organization Id available, skip
    if (!selectedOrganizationId) {
        console.debug('sagaRequestThemes cannot execute because one of the following is empty in the state: selectedOrganizationId');
        return;
    }

    // Indicate the beginning of the API fetch
    yield put(ThemeActions.indicateThemesFetchStart());

    // Fetch themes for the given Organization Id
    const response = yield callApi(apiServices.getAllThemes, selectedOrganizationId);

    if (isOk(response)) {

        // Saves themes fetched
        yield put(ThemeActions.onThemesRequestSuccess(response.data, selectedOrganizationId));

    } else {

        // Update the fetching state
        yield put(ThemeActions.onThemesRequestFailure());

        // Call error handler
        yield call(errorHandler, response);
    }
}

export function* sagaSaveTheme({themeObj, imageFiles, additionalImagesGetter}) {
    const {
        organizations: {
            selectedOrganizationId
        }
    } = yield select(state => state);

    // If no Organization Id available, skip
    if (!selectedOrganizationId) {
        console.debug('sagaSaveTheme cannot execute because one of the following is empty in the state: selectedOrganizationId');
        return;
    }

    // Indicate the beginning of the API call
    yield put(ThemeActions.indicateThemeSaveStart());

    // Before saving the theme, upload/save all the images, if any
    const imageURLs = {};
    const imageKeys = _keys(imageFiles);
    for (const imgKey of imageKeys) {
        const imgResponse = yield callApi(
            apiServices.uploadImage,
            selectedOrganizationId,
            imageFiles[imgKey]
        );
        if (isOk(imgResponse)) {
            imageURLs[imgKey] = imgResponse.data.key
        } else {
            // Update the api execution state
            yield put(ThemeActions.onThemeSaveFailure());

            // Call error handler
            yield call(errorHandler, imgResponse);

            // Image loading failed, can't proceed further
            return;
        }
    }

    const imageURLsUnflattened = {
        themeValuesObj: flatten.unflatten(imageURLs)
    };

    // There are also additional images that are not part of the Theme Specification.
    // If the getter is available, get all those images.
    // The result is an object where keys are flattened dot-separated paths
    // in the theme values object, and the values are concrete image getters -
    // i.e. functions that each take a blobResolver callback, which, in turn,
    // receives an image blob on that is retrieved.
    const additionalImageGetters = typeof additionalImagesGetter === "function"?
        additionalImagesGetter() : {};

    const additionalImgURLs = {};

    const additionalImgGetterKeys = _keys(additionalImageGetters);
    for (const getterKey of additionalImgGetterKeys) {

        const getter = additionalImageGetters[getterKey];
        if (typeof getter !== "function") {
            continue;
        }
        const imgBlobMeta = yield call(() => {
            return new Promise(function (resolve) {
                getter((blobData) => {
                    resolve({
                        key: getterKey,
                        blobData: blobData
                    });
                });
            })
        });

        if (!imgBlobMeta.key || !imgBlobMeta.blobData) {
            continue;
        }

        const imgResponse = yield callApi(
            apiServices.uploadImage,
            selectedOrganizationId,
            imgBlobMeta.blobData
        );

        if (isOk(imgResponse)) {
            additionalImgURLs[getterKey] = imgResponse.data.key
        } else {
            // Update the api execution state
            yield put(ThemeActions.onThemeSaveFailure());

            // Call error handler
            yield call(errorHandler, imgResponse);

            // Image loading failed, can't proceed further
            return;
        }
    }

    const additionalImageURLsUnflattened = {
        themeValuesObj: flatten.unflatten(additionalImgURLs)
    };


    // Embed URLs of the images we just uploaded/saved into the theme object.
    // The order of arguments is important!
    const themeObjEnriched = _defaultsDeep(
        {},
        imageURLsUnflattened,
        themeObj,
        additionalImageURLsUnflattened
    );

    const isEditingTheme = !!themeObj.id;

    // Save the theme for the given Organization Id
    let response;
    if (isEditingTheme) {

        // Editing an existing theme
        response = yield callApi(apiServices.editTheme, selectedOrganizationId, themeObjEnriched);

    } else {

        // Creating a new theme
        response = yield callApi(apiServices.saveTheme, selectedOrganizationId, themeObjEnriched);
    }

    if (isOk(response)) {

        // The theme object returned in the API response (always has the "id" field).
        const newThemeObj = response.data;

        // Saves themes fetched
        yield put(ThemeActions.onThemeSaveSuccess(newThemeObj));

        // Navigate back to the Themes page of this type
        const themesUrl = getThemesUrl(
            newThemeObj.themeType,
            THEME_ORDER_BY_MODIFIED_ON,
            false,
            newThemeObj.id
        );
        yield put(CommonActions.navigateTo(themesUrl));

    } else {

        // Update the api execution state
        yield put(ThemeActions.onThemeSaveFailure());

        // Call error handler
        yield call(errorHandler, response);
    }
}

export function* sagaDeleteTheme({themeId, themeName}) {
    const {
        organizations: {
            selectedOrganizationId
        }
    } = yield select(state => state);

    // If no Organization Id available, skip
    if (!selectedOrganizationId) {
        console.debug('sagaDeleteTheme cannot execute because one of the following is empty in the state: selectedOrganizationId');
        return;
    }

    // Indicate the beginning of the API call
    yield put(ThemeActions.indicateThemeDeleteStart(themeId));

    // Save the theme for the given Organization Id
    const response = yield callApi(apiServices.deleteTheme, selectedOrganizationId, themeId);

    if (isOk(response)) {

        yield put(ThemeActions.onThemeDeleteSuccess(themeId));
        showSuccessMessage(`Theme "${themeName}" has been deleted successfully.`)

    } else {

        // Update the api execution state
        yield put(ThemeActions.onThemeDeleteFailure());

        // Call error handler
        yield call(errorHandler, response);
    }
}

/**
 * Displays an error message.
 *
 * TODO: Create a universal error handler as in rewards-www
 * (it should not be specific to this module)
 *
 * @param response
 */
function errorHandler(response) {
    if (response.data) {
        showErrorResponse(response)
    }
}
