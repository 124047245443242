import React from 'react'
import PaginationComponent from './PaginationComponent'
import { pageSize } from '../../../utils/util-helper'

export const Pagination = ({
  handlePageChange,
  activePage,
  totalItems,
  disabled
}) => {
  if (!totalItems) {
    return null
  }
  return (
    <PaginationComponent
      activePage={activePage}
      totalItems={totalItems}
      pageSize={pageSize}
      onSelect={handlePageChange}
      disabled={disabled}
    />
  )
}
