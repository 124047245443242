import { connect } from 'react-redux'
import ResetPassword from './ResetPassword'
import AuthActions from '../../../../modules/auth'

const mapStateToProps = state => {
  return {
    fetching: state.auth.fetching
  }
}

export default connect(
  mapStateToProps,
  { ...AuthActions }
)(ResetPassword)
