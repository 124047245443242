import React, { Component } from 'react'
import { Button, FormGroup } from 'reactstrap'
import { Input } from '../../../../components/common'
import { Field, reduxForm } from 'redux-form'
import { requiredField, validateEmail } from '../../../../utils/form-validator'
import rtmLogo from '../../../../assets/img/auth/rtm-logo.png'
import { Link } from 'react-router-dom'

class ForgotPassword extends Component {
  handleFormSubmit = ({ email }) => {
    this.props.forgetPasswordRequest(email)
  }

  render() {
    const { handleSubmit, fetching } = this.props
    return (
      <div className="login-form-wrap">
        <div className="text-center">
          <img src={rtmLogo} alt="Realtime Media" />
        </div>

        <div className="auth-box card">
          <div className="card-block">
            <form
              id="forgotPasswordForm"
              noValidate
              onSubmit={handleSubmit(this.handleFormSubmit)}
            >
              <FormGroup className="m-t-20">
                <Field
                  name="email"
                  type="email"
                  required
                  disabled={fetching}
                  placeholder="Email"
                  className=""
                  groupText={<i className="feather icon-mail" />}
                  component={Input}
                />
              </FormGroup>

              <Button
                color="primary"
                type="submit"
                form="forgotPasswordForm"
                className="btn-md btn-block"
                disabled={fetching}
              >
                Reset Password
              </Button>
              <hr />
              <p className="text-center m-b-0 f-w-600">
                <Link to="/login">Back to Login</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const validate = values => {
  const errors = {}
  requiredField(values, errors, 'email')
  validateEmail(values, errors, 'email')
  return errors
}

export default reduxForm({
  validate,
  form: 'forgotPasswordForm' // a unique identifier for this form
})(ForgotPassword)
