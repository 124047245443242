import apisauce from 'apisauce'
import _ from 'lodash'
import { pageSize } from '../utils/util-helper'

const defaultPaging = {
  offset: 0,
  limit: pageSize
}

const getApiURL = () => {
  if (
    process.env.REACT_APP_HOST_ENV &&
    process.env.REACT_APP_HOST_ENV === 'PROD'
  ) {
    return 'https://api.rewards.rtm.com'
  } else {
    return 'https://testapi.rewards.rtm.com'
  }
}

export default (() => {
  const api = apisauce.create({
    baseURL: getApiURL()
  })

  api.addRequestTransform(request => {
    request.headers['Authorization'] = `Bearer ${sessionStorage.getItem(
      'auth_token'
    )}`
    request.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate'
    request.headers['Pragma'] = 'no-cache'
    request.headers['Expires'] = '0'
  })

  return {
    login: (email, password) => {
      return api.post('/users/login', { email, password })
    },
    logout: () => {
      return api.post('/users/logout')
    },
    forgotPassword: email => {
      return api.post('/users/forget-password', { email })
    },
    resetPassword: data => {
      return api.post('/users/reset-password', data)
    },
    signup: data => {
      return api.post('/users/sign-up', data)
    },
    getMyUser: () => {
      return api.get('/users/me')
    },
    editMyUser: formProps => {
      return api.put('/users/me', formProps)
    },
    refreshAuthToken: refreshToken => {
      return api.post('/users/me/refresh', { refreshToken })
    },
    getUser: (orgId, userId) => {
      return api.get(`/organizations/${orgId}/users/${userId}`)
    },
    inviteUser: (orgId, formData) => {
      return api.post(`/organizations/${orgId}/invites`, formData)
    },
    enableUser: (orgId, userId) => {
      return api.post(`/organizations/${orgId}/users/${userId}/enable`)
    },
    disableUser: (orgId, userId) => {
      return api.post(`/organizations/${orgId}/users/${userId}/disable`)
    },
    changeUserRole: (orgId, userId, role) => {
      return api.post(`/organizations/${orgId}/users/${userId}/role/${role}`)
    },
    removeInvite: (orgId, inviteId) => {
      return api.delete(`/organizations/${orgId}/invites/${inviteId}/remove`)
    },
    resendInvite: (orgId, inviteId) => {
      return api.post(`/organizations/${orgId}/invites/${inviteId}/resend`)
    },
    acceptInvite: token => {
      return api.post(`/invites/${token}/accept`)
    },
    declineInvite: token => {
      return api.post(`/invites/${token}/decline`)
    },
    getOrganizations: () => {
      return api.get('/organizations', { offset: 0, limit: 100, status: 1 })
    },
    getSubOrganizations: (orgId, paging = defaultPaging) => {
      return api.get(`/organizations/${orgId}/sub-organizations`, {
        ...paging,
        status: 1
      })
    },
    getOrganization: organizationId => {
      return api.get(`/organizations/${organizationId}`)
    },
    saveOrganization: obj => {
      if (obj.id) {
        return api.put(`/organizations/${obj.id}`, _.omit(obj, ['id']))
      } else {
        return api.post('/organizations', obj)
      }
    },
    deleteOrganization: organization => {
      return api.delete(`/organizations/${organization.id}`)
    },
    getOrganizationUsers: (orgId, paging = defaultPaging) => {
      return api.get(`/organizations/${orgId}/users`, paging)
    },
    getOrganizationInvites: (orgId, paging = defaultPaging) => {
      return api.get(`/organizations/${orgId}/invites`, paging)
    },
    getPromotions: (orgId, paging = defaultPaging) => {
      return api.get(`/organizations/${orgId}/promotions`, paging)
    },
    deletePromotion: (orgId, id) => {
      return api.delete(`/organizations/${orgId}/promotions/${id}`)
    },
    savePromotion: (orgId, formData) => {
      return api.post(`/organizations/${orgId}/promotions`, formData)
    },
    editPromotion: (orgId, promotionId, formData) => {
      return api.put(
        `/organizations/${orgId}/promotions/${promotionId}`,
        formData
      )
    },

    getAllThemes: orgId => {
      return api.get(`/organizations/${orgId}/themes`)
    },
    saveTheme: (orgId, themeObj) => {
      return api.post(`/organizations/${orgId}/themes`, themeObj)
    },
    editTheme: (orgId, themeObj) => {
      return api.put(`/organizations/${orgId}/themes/${themeObj.id}`, themeObj)
    },
    deleteTheme: (orgId, themeId) => {
      return api.delete(`/organizations/${orgId}/themes/${themeId}`)
    },

    uploadImage: (orgId, file) => {
      const formData = new FormData()
      formData.append('file', file)

      return api.post(`/organizations/${orgId}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    getFile: (orgId, key) => {
      // apisauce has problems with receiving files - use fetch() API instead
      return fetch(`${getApiURL()}/organizations/${orgId}/files/${key}`, {
        headers: new Headers({
          Authorization: `Bearer ${sessionStorage.getItem('auth_token')}`,
          'Content-Type': 'application/json'
        })
      }).then(response => response.blob())
    },
    getPromotion: (orgId, id, version) => {
      return api.get(`/organizations/${orgId}/promotions/${id}/${version}`)
    },
    getPromotionSummary: (orgId, id) => {
      return api.get(`/organizations/${orgId}/promotions/${id}/summaries`)
    },
    getPromotionTransactions: (orgId, id) => {
      return api.get(`/organizations/${orgId}/promotions/${id}/transactions`)
    },
    publishPromotion: (orgId, id) => {
      return api.post(`/organizations/${orgId}/promotions/${id}/publish`)
    },
    getOrganizationMembers: (orgId, paging = defaultPaging) => {
      return api.get(`/organizations/${orgId}/members`, paging)
    },
    getMemberDetails: (orgId, id) => {
      return api.get(`/organizations/${orgId}/members/${id}`)
    },
    getMemberTransactions: (orgId, id, paging = defaultPaging) => {
      return api.get(
        `/organizations/${orgId}/members/${id}/transactions`,
        paging
      )
    },
    getMemberPromotions: (orgId, id, paging = defaultPaging) => {
      return api.get(`/organizations/${orgId}/members/${id}/promotions`, paging)
    },
    getMemberSummaries: (orgId, id, paging = defaultPaging) => {
      return api.get(`/organizations/${orgId}/members/${id}/summaries`, paging)
    },
    getMemberPrizes: (orgId, id, paging = defaultPaging) => {
      return api.get(`/organizations/${orgId}/members/${id}/prizes`, paging)
    },
    submitAmoeEntries: (promoId, numOfEntries) => {
      return api.post(`/amoe/${promoId}/create-amoe-entries/${numOfEntries}`)
    },
    submitAmoeWinner: (promoId, data) => {
      return api.post(`/amoe/${promoId}/winner`, data)
    },
    playAmoeGame: promoId => {
      return api.post(`/amoe/${promoId}/play-instant-win`)
    },
    getWinners: promotionId => {
      return api.get(`/amoe/${promotionId}/winners`)
    },
    downloadWinners: (orgId, start, end, includePrevious) => {
      return api.get(
        `/organizations/${orgId}/promotions-winners?start=${start}&end=${end}&includePrevious=${includePrevious}`
      )
    }
  }
})()
