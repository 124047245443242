import React, { Component } from 'react'
import { Button, FormGroup } from 'reactstrap'
import { Input } from '../../../../components/common'
import rtmLogo from '../../../../assets/img/auth/rtm-logo.png'
import { Field, reduxForm } from 'redux-form'
import { requiredField, validateEmail } from '../../../../utils/form-validator'
import { Link } from 'react-router-dom'

class Login extends Component {
  handleFormSubmit = formProps => {
    return this.props.loginRequest(formProps.email, formProps.password)
  }

  render() {
    const { handleSubmit, fetching } = this.props
    return (
      <div className="login-form-wrap">
        <div className="text-center">
          <img src={rtmLogo} alt="Realtime Media" />
        </div>

        <div className="auth-box card">
          <div className="card-block">
            <form
              id="loginForm"
              noValidate
              onSubmit={handleSubmit(this.handleFormSubmit)}
            >
              <FormGroup className="m-t-20">
                <Field
                  name="email"
                  type="email"
                  required
                  disabled={fetching}
                  placeholder="Email"
                  className=""
                  groupText={<i className="feather icon-mail" />}
                  component={Input}
                />
              </FormGroup>

              <FormGroup>
                <Field
                  name="password"
                  type="password"
                  required
                  disabled={fetching}
                  placeholder="Password"
                  className=""
                  groupText={<i className="feather icon-lock" />}
                  component={Input}
                />
              </FormGroup>

              <div className="m-t-25 clearfix">
                <div className="checkbox-zoom">
                  <label>
                    <input type="checkbox" value="" />
                    <span className="cr">
                      <i className="cr-icon icofont icofont-ui-check" />
                    </span>
                    <span className="text-inverse">Remember me</span>
                  </label>
                </div>
              </div>

              <div className="m-t-25">
                <Button
                  color="primary"
                  type="submit"
                  form="loginForm"
                  className="btn-block btn-md"
                  disabled={fetching}
                >
                  LOGIN
                </Button>
              </div>

              <div className="m-t-25 clearfix">
                <div className="f-left f-w-600">
                  <Link to="/sign-up">Create account</Link>
                </div>

                <div className="f-right f-w-600">
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const validate = values => {
  const errors = {}
  requiredField(values, errors, 'email')
  requiredField(values, errors, 'password')
  validateEmail(values, errors, 'email')
  return errors
}

export default reduxForm({
  validate,
  form: 'loginForm'
})(Login)
