import { call, put } from 'redux-saga/effects'
import AuthActions from '../modules/auth'
import CommonActions from '../modules/common'
import OrganizationActions from '../modules/organizations'
import apiServices from '../api-services'
import {
  showErrorResponse,
  showSuccessMessage
} from '../utils/notification-handler'
import { callApi, isOk } from './helper'

export function* login({ email, password }) {
  let response = yield callApi(apiServices.login, email, password)
  if (isOk(response)) {
    const token = localStorage.getItem('invitation_token')
    if (token) {
      const { authToken, refreshToken } = response.data.token
      sessionStorage.setItem('auth_token', authToken)
      sessionStorage.setItem('refresh_token', refreshToken)
      const acceptResponse = yield callApi(apiServices.acceptInvite, token)
      localStorage.removeItem('invitation_token')
      if (isOk(acceptResponse)) {
        response.data.token = acceptResponse.data.token
        showSuccessMessage(`You've successfully accepted invitation.`)
      } else {
        yield call(errorHandler, acceptResponse)
      }
    }
    yield put(AuthActions.loginSuccess(response.data))
    yield put(CommonActions.navigateTo('/home'))
  } else {
    yield call(errorHandler, response)
  }
}

export function* logout() {
  const response = yield callApi(apiServices.logout)
  if (isOk(response)) {
    yield put(AuthActions.logoutSuccess())
    yield put(CommonActions.navigateTo('/login'))
  }
}

export function* forgetPassword({ email }) {
  const response = yield callApi(apiServices.forgotPassword, email)
  if (isOk(response)) {
    yield put(AuthActions.forgetPasswordSuccess())
    showSuccessMessage('Please check your email for reset password link.')
    yield put(CommonActions.navigateTo('/home'))
  } else {
    yield call(errorHandler, response)
  }
}

export function* getMyUser({ initial }) {
  const response = yield callApi(apiServices.getMyUser)
  if (isOk(response)) {
    yield put(AuthActions.getMyUserSuccess(response.data))
    if (initial) {
      yield put(OrganizationActions.organizationsRequest())
    }
  } else {
    yield call(errorHandler, response)
  }
}

export function* signup({ payload }) {
  let response = yield callApi(apiServices.signup, payload)
  if (isOk(response)) {
    const token = localStorage.getItem('invitation_token')
    if (token) {
      const { authToken, refreshToken } = response.data.token
      sessionStorage.setItem('auth_token', authToken)
      sessionStorage.setItem('refresh_token', refreshToken)
      const acceptResponse = yield callApi(apiServices.acceptInvite, token)
      localStorage.removeItem('invitation_token')
      if (isOk(acceptResponse)) {
        response.data.token = acceptResponse.data.token
        showSuccessMessage(`You've successfully accepted invitation.`)
      } else {
        yield call(errorHandler, acceptResponse)
      }
    }
    yield put(AuthActions.loginSuccess(response.data))
    showSuccessMessage('You have successfully signed up.')
    yield put(CommonActions.navigateTo('/home'))
  } else {
    yield call(errorHandler, response)
  }
}

export function* resetPassword({ payload }) {
  const response = yield callApi(apiServices.resetPassword, payload)
  if (isOk(response)) {
    yield put(AuthActions.resetPasswordSuccess())
    showSuccessMessage("You've successfully reset the password. Please login.")
    yield put(CommonActions.navigateTo('/login'))
  } else {
    yield call(errorHandler, response)
  }
}

export function* editMyUser({ formProps }) {
  const response = yield callApi(apiServices.editMyUser, formProps)

  if (isOk(response)) {
    yield put(AuthActions.editMyUserSuccess(response.data.name))
    showSuccessMessage(`You've successfully changed your information.`)
  } else {
    yield call(errorHandler, response)
  }
}

function* errorHandler(response) {
  if (response.data) {
    showErrorResponse(response)
  }
  yield put(AuthActions.onError())
}
