import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import history from '../history'
import createRootReducer from '../reducers'
import rootSaga from '../sagas'

export default function configureStore() {
  const routeMiddleware = routerMiddleware(history)
  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(sagaMiddleware, routeMiddleware))
  )

  sagaMiddleware.run(rootSaga)

  return store
}
