import { createReducer, createActions } from 'reduxsauce'
import { AuthTypes } from './auth'
import { OrganizationsTypes } from './organizations'
import _ from 'lodash'

const { Types, Creators } = createActions(
  {
    organizationUsersRequest: ['paging'],
    organizationUsersRequestSuccess: ['users'],
    getUser: ['userId'],
    getUserSuccess: ['data'],
    enableUser: ['userId'],
    enableUserSuccess: ['user'],
    disableUser: ['userId'],
    disableUserSuccess: ['user'],
    changeUserRole: ['userId', 'role'],
    changeUserRoleSuccess: ['orgId', 'role'],
    setIsEditing: null,
    resetIsEditing: null,
    resetSelectedUser: null,
    updateFilter: ['filter'],
    onError: ['error']
  },
  {
    prefix: 'USERS_'
  }
)

export const UsersTypes = Types

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  users: null,
  selectedUser: null,
  isEditing: false,
  fetching: false,
  error: null,
  filter: {
    offset: 0,
    q: '',
    status: ''
  }
}

/* ------------- Reducers ------------- */

export const request = state => {
  return {
    ...state,
    fetching: true,
    error: null
  }
}

export const requestWithPaging = (state, { paging }) => {
  return {
    ...state,
    fetching: !paging, // if changing page, don't fetch but use isLoading
    isLoading: !!paging
  }
}

export const onError = (state, action) => {
  return {
    ...state,
    fetching: false,
    error: action.error
  }
}

export const getUserSuccess = (state, { data }) => {
  return {
    ...state,
    fetching: false,
    selectedUser: {
      ...data,
      organizations: _.uniqBy(data.organizations, 'organizationId')
    }
  }
}

export const organizationUsersRequestSuccess = (state, { users }) => {
  return {
    ...state,
    fetching: false,
    isLoading: false,
    users: users.data,
    total: users.meta.total
  }
}

export const disableUserSuccess = (state, { user }) => {
  let users = null

  if (state.users) {
    const i = _.findIndex(state.users, u => u.id === user.id)

    users = [...state.users]
    users[i] = user
  }

  return {
    ...state,
    fetching: false,
    users,
    selectedUser: user
  }
}

export const enableUserSuccess = (state, { user }) => {
  let users = null

  if (state.users) {
    const i = _.findIndex(state.users, u => u.id === user.id)

    users = [...state.users]
    users[i] = user
  }

  return {
    ...state,
    fetching: false,
    users,
    selectedUser: user
  }
}

export const changeUserRoleSuccess = (state, { orgId, role }) => {
  return {
    ...state,
    fetching: false,
    isEditing: false,
    selectedUser: {
      ...state.selectedUser,
      ...state.selectedUser.organizations.map(org => {
        if (org.organizationId === orgId) {
          org.role = role
        }

        return org
      })
    }
  }
}

export const setIsEditing = state => {
  return {
    ...state,
    isEditing: true
  }
}

export const resetIsEditing = state => {
  return {
    ...state,
    isEditing: false
  }
}

export const resetUsers = state => {
  return {
    ...state,
    users: null
  }
}

export const resetSelectedUser = state => {
  return {
    ...state,
    selectedUser: null,
    isEditing: false
  }
}

export const updateFilter = (state, { filter }) => {
  return {
    ...state,
    filter: { ...filter }
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_ERROR]: onError,
  [Types.ORGANIZATION_USERS_REQUEST]: requestWithPaging,
  [Types.ORGANIZATION_USERS_REQUEST_SUCCESS]: organizationUsersRequestSuccess,
  [Types.GET_USER]: request,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.ENABLE_USER]: request,
  [Types.DISABLE_USER_SUCCESS]: disableUserSuccess,
  [Types.DISABLE_USER]: request,
  [Types.ENABLE_USER_SUCCESS]: enableUserSuccess,
  [Types.CHANGE_USER_ROLE]: request,
  [Types.CHANGE_USER_ROLE_SUCCESS]: changeUserRoleSuccess,
  [Types.SET_IS_EDITING]: setIsEditing,
  [Types.RESET_IS_EDITING]: resetIsEditing,
  [Types.RESET_SELECTED_USER]: resetSelectedUser,
  [Types.UPDATE_FILTER]: updateFilter,
  [AuthTypes.EDIT_MY_USER_SUCCESS]: resetIsEditing,
  [OrganizationsTypes.SET_ORGANIZATION_ID]: resetUsers
})
