// Project-wide constants

// Indicate AJAX/API Fetching State

export const NEVER_FETCHED = 'NEVER_FETCHED';
export const BEING_FETCHED = 'BEING_FETCHED';
export const FETCHED_SUCCESSFULLY = 'FETCHED_SUCCESSFULLY';
export const FETCHING_FAILED = 'FETCHING_FAILED';

export const FETCHING_STATES = [
    NEVER_FETCHED,
    BEING_FETCHED,
    FETCHED_SUCCESSFULLY,
    FETCHING_FAILED
];

// Indicate AJAX/API Call State
export const READY_TO_CALL = 'READY_TO_CALL';
export const BEING_CALLED = 'BEING_CALLED';
export const LAST_CALL_SUCCEEDED = 'LAST_CALL_SUCCESSFUL';
export const LAST_CALL_FAILED = 'LAST_CALL_UNSUCCESSFUL';

export const API_CALL_STATES = [
    READY_TO_CALL,
    BEING_CALLED,
    LAST_CALL_SUCCEEDED,
    LAST_CALL_FAILED
];

/**
 * Returns TRUE if the value is a boolean TRUE, "yes", "on", "true", "1" (case-insensitive),
 * or a positive number. Otherwise, returns FALSE.
 *
 * @param value
 * @return {boolean}
 */
export function isFlagChecked(value) {
    return ( (typeof value === "number") && value > 0)
        || (typeof value === "string" && ["yes", "on", "true", "1"].indexOf(value.toLowerCase()) > -1)
        || (typeof value === "boolean" && value);
}

// The THEME_TYPE_XXX constants below must correspond
// to the PromotionThemeType enum values in c# API.

export const THEME_TYPE_SPIN_TO_WIN = 'SpinToWin';
export const THEME_TYPE_PLINKO = 'Plinko';
export const THEME_TYPE_MEMORY_MATCH = 'MemoryMatch';

export const THEME_TYPES = [
    THEME_TYPE_SPIN_TO_WIN,
    THEME_TYPE_PLINKO,
    THEME_TYPE_MEMORY_MATCH
];

/**
 * Gets a human readable name for the given THEME_TYPE_XXX string constant.
 * The constants correspond to the PromotionThemeType enum values in c# API.
 *
 * @param {string} type
 * @return {string}
 */
export function getThemeTypeHumanName(type) {
    switch (type) {
        case THEME_TYPE_SPIN_TO_WIN:
            return 'Spin to Win';

        case THEME_TYPE_PLINKO:
            // Plinko game was renamed to ChipDrop, but the "Plinko" identifier
            // is used everywhere throughout the code and in database.
            return 'Chip Drop';

        case THEME_TYPE_MEMORY_MATCH:
            return 'Memory Match';

        default:
            return 'Unknown Theme Type';
    }
}

export const THEME_ORDER_BY_MODIFIED_ON = 'modifiedOn';
export const THEME_ORDER_BY_CREATED_ON = 'createdOn';
export const THEME_ORDER_BY_THEME_NAME = 'themeName';


/**
 * All possible column names to order themes by.
 * @type {string[]}
 */
export const THEME_ORDER_BYs = [
    THEME_ORDER_BY_MODIFIED_ON,
    THEME_ORDER_BY_CREATED_ON,
    THEME_ORDER_BY_THEME_NAME
];

export function getDefaultIsThemeOrderingAscendingByColumn(col) {
    // Default ordering direction depends on the column we order by
    switch (col) {
        case THEME_ORDER_BY_THEME_NAME:
            return true;

        case THEME_ORDER_BY_MODIFIED_ON:
        case THEME_ORDER_BY_CREATED_ON:
            return false;

        default:
            return true;
    }
}
