import { Component } from 'react'

class InvitationAccepted extends Component {
  componentDidMount() {
    this.props.acceptInvite(this.props.match.params.token)
  }
  render() {
    return null
  }
}

export default InvitationAccepted
