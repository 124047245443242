import { call, put, select } from 'redux-saga/effects'
import OrganizationsActions from '../modules/organizations'
import CommonActions from '../modules/common'
import AuthActions from '../modules/auth'
import apiServices from '../api-services'
import { callApi, isOk } from './helper'
import {
  showErrorResponse,
  showSuccessMessage
} from '../utils/notification-handler'
import { initialize } from 'redux-form'
import _ from 'lodash'

export function* organizationsRequest() {
  const response = yield callApi(apiServices.getOrganizations)
  if (isOk(response)) {
    yield put(OrganizationsActions.organizationsRequestSuccess(response.data))
    const orgID = getSelectedOrganizationId(response.data)
    yield put(OrganizationsActions.setOrganizationId(orgID))
    if (orgID) {
      yield put(OrganizationsActions.getSubOrgs(orgID))
    } else {
      yield put(
        OrganizationsActions.subOrgsSuccess({ data: [], meta: { total: 0 } })
      )
    }
  } else {
    yield call(errorHandler, response)
  }
}

export function* getSubOrgs({ orgId, paging }) {
  if (paging) {
    yield put(OrganizationsActions.updateFilter(paging))
  }
  const response = yield callApi(apiServices.getSubOrganizations, orgId, paging)
  if (isOk(response)) {
    yield put(OrganizationsActions.subOrgsSuccess(response.data))
  } else {
    yield call(errorHandler, response)
  }
}

export function* organizationSaveRequest({ formData }) {
  const response = yield callApi(apiServices.saveOrganization, formData)
  if (isOk(response)) {
    showSuccessMessage('Organization has been saved successfully!')
    let result = response.data
    if (!formData.id) {
      result = response.data.data
      const { authToken, refreshToken } = response.data.token
      sessionStorage.setItem('auth_token', authToken)
      sessionStorage.setItem('refresh_token', refreshToken)
    }
    // refresh "my orgs" by requesting my user data
    yield put(AuthActions.getMyUser())
    // if this is the first org being added, reload the list so it's set as active one
    const allOrganizations = yield select(
      state => state.organizations.organizations
    )
    if (allOrganizations.length === 0) {
      yield put(OrganizationsActions.organizationsRequest())
    } else {
      yield put(OrganizationsActions.organizationSaveRequestSuccess(result))
    }
    yield put(CommonActions.navigateTo('/organizations'))
  } else {
    yield call(errorHandler, response)
  }
}

export function* organizationDeleteRequest({ organization }) {
  const response = yield callApi(apiServices.deleteOrganization, organization)
  if (isOk(response)) {
    showSuccessMessage(
      `Organization "${organization.name}" has been deleted successfully.`
    )
    yield put(
      OrganizationsActions.organizationDeleteRequestSuccess(organization.id)
    )
    yield put(CommonActions.navigateTo('/organizations'))
  } else {
    yield call(errorHandler, response)
  }
}

export function* editOrganization({ organizationId }) {
  const response = yield callApi(apiServices.getOrganization, organizationId)
  if (isOk(response)) {
    const { data } = response
    yield put(
      initialize('editOrgForm', {
        name: data.name,
        parentId: data.parentId,
        memberProgram: data.memberProgram
      })
    )
    yield put(OrganizationsActions.onEditOrganizationReady(data))
  } else {
    yield call(errorHandler, response)
  }
}

function* errorHandler(response) {
  if (response.data) {
    showErrorResponse(response)
  }
  yield put(OrganizationsActions.onError())
}

function getSelectedOrganizationId({ data }) {
  if (data && data.length > 0) {
    const orgId = sessionStorage.getItem('org_id')
    if (orgId) {
      return orgId
    } else {
      const activeOrg = _.find(data, x => x.status === 'Active')
      return activeOrg ? activeOrg.id : null
    }
  }

  return null
}
