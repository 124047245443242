import React, { Component } from 'react'
import rtmLogo from '../../../../assets/img/auth/rtm-logo.png'
import { Button, FormGroup } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import { Input } from '../../../../components/common'
import {
  requiredField,
  validatePassword
} from '../../../../utils/form-validator'

class ResetPassword extends Component {
  handleFormSubmit = ({ password }) => {
    this.props.resetPasswordRequest({
      password,
      token: this.props.match.params.token
    })
  }

  render() {
    const { handleSubmit, fetching } = this.props
    return (
      <div className="login-form-wrap">
        <div className="text-center">
          <img src={rtmLogo} alt="Realtime Media" />
        </div>

        <div className="auth-box card">
          <div className="card-block">
            <form
              id="resetPassForm"
              noValidate
              onSubmit={handleSubmit(this.handleFormSubmit)}
            >
              <FormGroup>
                <Field
                  name="password"
                  type="password"
                  required
                  disabled={fetching}
                  placeholder="New Password"
                  className=""
                  groupText={<i className="feather icon-lock" />}
                  component={Input}
                />
              </FormGroup>

              <div className="m-t-25">
                <Button
                  color="primary"
                  type="submit"
                  form="resetPassForm"
                  className="btn-block btn-md"
                  disabled={fetching}
                >
                  Reset
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const validate = values => {
  const errors = {}
  requiredField(values, errors, 'password')
  validatePassword(values, errors, 'password')
  return errors
}

export default reduxForm({
  validate,
  form: 'resetPassForm' // a unique identifier for this form
})(ResetPassword)
