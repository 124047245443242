import moment from 'moment'
import _ from 'lodash'
import accounting from 'accounting'
import PasswordValidator from 'password-validator'

const schema = new PasswordValidator()
schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces()

export const requiredField = (values, errors, name) => {
  if (!values[name]) {
    errors[name] = 'Please enter this field.'
  }
}

export const validateEmail = (values, errors, name) => {
  if (
    values[name] &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(values[name])
  ) {
    errors[name] = 'Invalid email address.'
  }
}

export const validateUSZipCode = (values, errors, name) => {
  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  const value = values[name]
  if (value && !zipRegex.test(value)) {
    errors[name] = 'Invalid US Zip Code.'
  }
}

export const validatePhone = (values, errors, name) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  // Removes space before validation to allow user to enter space.
  const numberWithoutSpace = values[name]
    ? values[name].replace(/\s/g, '')
    : undefined
  // Alows number to begin with 00 or +.
  if (numberWithoutSpace && !phoneRegex.test(numberWithoutSpace)) {
    errors[name] = 'Invalid phone number!'
  }
}

export const validateDate = (values, errors, name, maxDate, minDate) => {
  if (values[name]) {
    const format = 'MM/DD/YYYY'
    const mDate = moment(values[name], format, true)
    if (!mDate.isValid()) {
      errors[name] = 'Invalid date'
    } else {
      if (maxDate && mDate.isAfter(moment(maxDate, format, true), 'minute')) {
        errors[name] = `Date can't be after: ${moment(maxDate, format).format(
          'MM/DD/YYYY LT'
        )}`
      }
      if (minDate && mDate.isBefore(moment(minDate, format, true), 'minute')) {
        errors[name] = `Date can't be before: ${moment(minDate, format).format(
          'MM/DD/YYYY LT'
        )}`
      }
    }
  }
}

export const validateStartDate = (values, errors, name, minDate) => {
  if (values[name]) {
    const format = 'MM/DD/YYYY'
    const mDate = moment(values[name][0], format, true)
    if (minDate && mDate.isBefore(moment(minDate, format, true), 'minute')) {
      errors[name] = `Start Date can't be before: ${moment(
        minDate,
        format
      ).format('MM/DD/YYYY LT')}`
    }
  }
}

export const maxLengthExceeded = (values, errors, name, max) => {
  const value = values[name]
  if (value && value.length > max) {
    errors[name] = `Max ${max} characters are allowed`
  }
}

export const maxFileSize = (values, errors, name, maxBytes) => {
  const file = values[name]
  if (file && file.size > maxBytes) {
    let sizeMb = maxBytes / 1024 / 1024
    if (!Number.isInteger(sizeMb)) {
      sizeMb = sizeMb.toFixed(2)
    }
    errors[name] = `File is too large. Maximum supported size is ${sizeMb}MB.`
  }
}

export const trimFormFields = formObj => {
  const formData = _.omit(formObj, [undefined])
  Object.keys(formData).forEach(prop => {
    const item = formData[prop]
    if (item) {
      // trim fields if they're strings
      if (item instanceof String || typeof item === 'string') {
        formData[prop] = item.trim()
      } else if (item === Object(item)) {
        // if detected that field contain object, go recursively through its props and trim
        trimFormFields(item)
      }
    }
  })

  return formData
}

export const validateLink = (values, errors, name) => {
  const externalRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i
  const value = values[name]
  if (value && (!externalRegex.test(value) && !value.startsWith('/'))) {
    errors[name] = 'Invalid link format.'
  }
}

export const requiredOption = (values, errors, name) => {
  if (values[name] && values[name].length === 0) {
    errors[name] = 'Please select one option.'
  }
}

export const positiveNumber = (values, errors, name) => {
  if (values[name]) {
    const number = values[name]
    if (isNaN(number) || number <= 0) {
      errors[name] = 'Value must be positive number.'
    }
  }
}

export const isInteger = (values, errors, name) => {
  if (values[name]) {
    const number = Number(values[name])
    if (isNaN(number) || !Number.isInteger(number)) {
      errors[name] = 'Value must be whole integer.'
    }
  }
}

export const positiveAmount = (values, errors, name) => {
  if (values[name]) {
    const unformatAmount = accounting.unformat(values[name])
    if (unformatAmount <= 0) {
      errors[name] = 'Amount must be greater than zero.'
    }
  }
}

export const checkMinAmount = (values, errors, name, minNumber) => {
  if (values[name]) {
    const number = values[name]
    if (number < minNumber) {
      errors[name] = `Minimum allowed amount is ${minNumber}.`
    }
  }
}

export const checkMaxAmount = (values, errors, name, maxNumber) => {
  if (values[name]) {
    const number = values[name]
    if (number > maxNumber) {
      errors[name] = `Minimum allowed amount is ${maxNumber}.`
    }
  }
}

export const validateStartEndDate = (values, errors, name) => {
  if (!values[name] || !values[name][0] || !values[name][1]) {
    errors[name] = 'Please enter both fields.'
  }
}

/*eslint-disable */
export const validatePassword = (values, errors, name) => {
  if (!values[name]) {
    return
  }
  const failed = []
  const result = schema.validate(values[name], { list: true })
  if (result.includes('min')) {
    failed.push('Password must be at least 8 characters long.')
  }
  if (result.includes('max')) {
    failed.push('Password must be up to 100 characters long.')
  }
  if (result.includes('uppercase')) {
    failed.push('Password must contain at least one uppercase letter.')
  }
  if (result.includes('lowercase')) {
    failed.push('Password must contain at least one lowercase letter.')
  }
  if (result.includes('digits')) {
    failed.push('Password must contain at least one digit.')
  }
  if (result.includes('spaces')) {
    failed.push('Password must not contain spaces.')
  }

  if (failed.length > 0) {
    errors[name] = failed
  }
}
/*eslint-enable */
