import { createReducer, createActions } from 'reduxsauce'
import { OrganizationsTypes } from './organizations'

const { Types, Creators } = createActions(
  {
    organizationMembersRequest: null,
    organizationMembersRequestSuccess: ['members'],
    getMemberDetails: ['id'],
    onMemberDetailsSuccess: ['data'],
    resetMember: null,
    updateFilter: ['filter'],
    onError: ['error']
  },
  {
    prefix: 'MEMBERS_'
  }
)

export const MembersTypes = Types

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  members: null,
  fetching: false,
  error: null,
  filter: {
    offset: 0,
    q: '',
    status: ''
  }
}

/* ------------- Reducers ------------- */

export const request = state => {
  return {
    ...state,
    fetching: true,
    error: null
  }
}

export const requestWithPaging = (state, { paging }) => {
  return {
    ...state,
    fetching: !paging, // if changing page, don't fetch but use isLoading
    isLoading: !!paging
  }
}

export const onError = (state, action) => {
  return {
    ...state,
    fetching: false,
    error: action.error
  }
}

export const organizationMembersRequestSuccess = (state, { members }) => {
  return {
    ...state,
    fetching: false,
    isLoading: false,
    members: members.data,
    total: members.meta.total
  }
}

export const resetMembers = state => {
  return {
    ...state,
    members: null
  }
}

export const resetMemberDetails = state => {
  return {
    ...state,
    memberDetails: null
  }
}

export const onMemberDetailsSuccess = (state, { data }) => {
  return {
    ...state,
    memberDetails: data,
    fetching: false
  }
}

export const updateFilter = (state, { filter }) => {
  return {
    ...state,
    filter: { ...filter }
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_ERROR]: onError,
  [Types.ORGANIZATION_MEMBERS_REQUEST]: requestWithPaging,
  [Types.ORGANIZATION_MEMBERS_REQUEST_SUCCESS]: organizationMembersRequestSuccess,
  [Types.GET_MEMBER_DETAILS]: request,
  [Types.ON_MEMBER_DETAILS_SUCCESS]: onMemberDetailsSuccess,
  [Types.RESET_MEMBER]: resetMemberDetails,
  [Types.UPDATE_FILTER]: updateFilter,
  [OrganizationsTypes.SET_ORGANIZATION_ID]: resetMembers
})
