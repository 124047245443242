import { call, put, select } from 'redux-saga/effects'
import UserActions from '../modules/users'
import { callApi } from './helper'

import apiServices from '../api-services'
import {
  showErrorResponse,
  showSuccessMessage
} from '../utils/notification-handler'
import { isOk } from './helper'

export function* organizationUsersRequest({ paging }) {
  if (paging) {
    yield put(UserActions.updateFilter(paging))
  }
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(
    apiServices.getOrganizationUsers,
    orgId,
    paging
  )

  if (isOk(response)) {
    yield put(UserActions.organizationUsersRequestSuccess(response.data))
  } else {
    yield call(errorHandler, response)
  }
}

export function* getUser({ userId }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.getUser, orgId, userId)

  if (isOk(response)) {
    yield put(UserActions.getUserSuccess(response.data))
  } else {
    yield call(errorHandler, response)
  }
}

export function* disableUser({ userId }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.disableUser, orgId, userId)

  if (isOk(response)) {
    yield put(UserActions.disableUserSuccess(response.data))
    showSuccessMessage(`You've successfully disabled a user.`)
  } else {
    yield call(errorHandler, response)
  }
}

export function* enableUser({ userId }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiServices.enableUser, orgId, userId)

  if (isOk(response)) {
    yield put(UserActions.enableUserSuccess(response.data))
    showSuccessMessage(`You've successfully enabled a user.`)
  } else {
    yield call(errorHandler, response)
  }
}

export function* changeUserRole({ userId, role }) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(
    apiServices.changeUserRole,
    orgId,
    userId,
    role
  )

  if (isOk(response)) {
    yield put(UserActions.changeUserRoleSuccess(orgId, role))
    showSuccessMessage(`You've successfully changed user role.`)
  } else {
    yield call(errorHandler, response)
  }
}

function* errorHandler(response) {
  if (response.data) {
    showErrorResponse(response)
  }
  yield put(UserActions.onError())
}
