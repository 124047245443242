import React, { Component } from 'react'
import { connect } from 'react-redux'
import ConfirmationDialogActions from '../../modules/confirmation-dialog'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export class ConfirmationDialog extends Component {
  render() {
    const {
      confirmationText,
      closeConfirmation,
      callback,
      isOpen,
      type
    } = this.props
    return (
      <Modal
        isOpen={isOpen}
        toggle={closeConfirmation}
        className={`modal-${type || 'danger'}`}
      >
        <ModalHeader toggle={closeConfirmation}>{'Please confirm'}</ModalHeader>
        {isOpen && (
          <div>
            <ModalBody>
              <p className="text-center">
                <strong>{confirmationText}</strong>
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                className={`btn-${type || 'danger'}`}
                onClick={() => {
                  callback(true)
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  callback(false)
                }}
              >
                No
              </Button>
            </ModalFooter>
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.confirmationDialog.isOpen,
    confirmationText: state.confirmationDialog.confirmationText,
    callback: state.confirmationDialog.callback
  }
}

export default connect(
  mapStateToProps,
  ConfirmationDialogActions
)(ConfirmationDialog)
