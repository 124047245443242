import { createReducer, createActions } from 'reduxsauce'
import _ from 'lodash'

const { Types, Creators } = createActions(
  {
    organizationsRequest: null,
    organizationsRequestSuccess: ['organizations'],
    organizationSaveRequest: ['formData'],
    organizationSaveRequestSuccess: ['item'],
    organizationDeleteRequest: ['organization'],
    organizationDeleteRequestSuccess: ['id'],
    editOrganization: ['organizationId'],
    onEditOrganizationReady: ['item'],
    setOrganizationId: ['orgId'],
    resetEditingOrganization: null,
    updateFilter: ['filter'],
    getSubOrgs: ['orgId', 'paging'],
    subOrgsSuccess: ['organizations'],
    onError: ['error']
  },
  {
    prefix: 'ORGS_'
  }
)

export const OrganizationsTypes = Types

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  organizations: null,
  scopedOrgs: null,
  fetching: false,
  submitting: false,
  selectedOrganizationId: null,
  error: null,
  editingOrganization: null,
  filter: {
    offset: 0,
    q: '',
    status: ''
  }
}

/* ------------- Reducers ------------- */

export const requestWithPaging = (state, { paging }) => {
  return {
    ...state,
    fetching: !paging, // if changing page, don't fetch but use isLoading
    isLoading: !!paging
  }
}

export const request = state => {
  return {
    ...state,
    fetching: true,
    error: null
  }
}

export const organizationsRequestSuccess = (state, { organizations }) => {
  return {
    ...state,
    fetching: false,
    isLoading: false,
    organizations: organizations.data
  }
}

export const subOrgsSuccess = (state, { organizations }) => {
  return {
    ...state,
    fetching: false,
    isLoading: false,
    scopedOrgs: organizations.data,
    total: organizations.meta.total
  }
}

export const organizationSaveRequestSuccess = (state, { item }) => {
  const orgIndex = _.findIndex(state.organizations, x => x.id === item.id)
  const scopedIndex = _.findIndex(state.scopedOrgs, x => x.id === item.id)
  return {
    ...state,
    submitting: false,
    fetching: false,
    organizations:
      orgIndex > -1
        ? state.organizations.map((x, i) => (i === orgIndex ? { ...item } : x))
        : [...state.organizations, item],
    scopedOrgs:
      scopedIndex > -1
        ? state.scopedOrgs.map((x, i) => (i === scopedIndex ? { ...item } : x))
        : [...state.scopedOrgs, item],
    total: scopedIndex === -1 ? state.total + 1 : state.total
  }
}

export const organizationDeleteRequest = state => {
  return {
    ...state,
    fetching: true
  }
}

export const organizationDeleteRequestSuccess = (state, { id }) => {
  return {
    ...state,
    fetching: false,
    organizations: state.organizations.filter(x => x.id !== id),
    scopedOrgs: state.scopedOrgs.filter(x => x.id !== id),
    total: state.total - 1
  }
}

export const onError = (state, action) => {
  return {
    ...state,
    fetching: false,
    submitting: false,
    error: action.error
  }
}

export const setOrganizationId = (state, { orgId }) => {
  if (orgId) {
    sessionStorage.setItem('org_id', orgId)
  }

  return {
    ...state,
    selectedOrganizationId: orgId,
    scopedOrgs: null,
    filter: {
      offset: 0,
      q: '',
      status: ''
    }
  }
}

export const onEditOrganizationReady = (state, { item }) => {
  return {
    ...state,
    fetching: false,
    editingOrganization: item
  }
}

export const resetEditingOrganization = state => {
  return {
    ...state,
    editingOrganization: null
  }
}

export const updateFilter = (state, { filter }) => {
  return {
    ...state,
    filter: { ...filter }
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ORGANIZATIONS_REQUEST]: request,
  [Types.ORGANIZATIONS_REQUEST_SUCCESS]: organizationsRequestSuccess,
  [Types.ORGANIZATION_SAVE_REQUEST]: request,
  [Types.ORGANIZATION_SAVE_REQUEST_SUCCESS]: organizationSaveRequestSuccess,
  [Types.ORGANIZATION_DELETE_REQUEST]: organizationDeleteRequest,
  [Types.ORGANIZATION_DELETE_REQUEST_SUCCESS]: organizationDeleteRequestSuccess,
  [Types.SET_ORGANIZATION_ID]: setOrganizationId,
  [Types.EDIT_ORGANIZATION]: request,
  [Types.ON_EDIT_ORGANIZATION_READY]: onEditOrganizationReady,
  [Types.RESET_EDITING_ORGANIZATION]: resetEditingOrganization,
  [Types.UPDATE_FILTER]: updateFilter,
  [Types.GET_SUB_ORGS]: requestWithPaging,
  [Types.SUB_ORGS_SUCCESS]: subOrgsSuccess,
  [Types.ON_ERROR]: onError
})
