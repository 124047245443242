import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { resettableReducer } from 'reduxsauce'
import { connectRouter } from 'connected-react-router'

import { reducer as auth } from '../modules/auth'
import { reducer as users } from '../modules/users'
import { reducer as organizations } from '../modules/organizations'
import { reducer as invites } from '../modules/invites'
import { reducer as confirmationDialog } from '../modules/confirmation-dialog'
import { reducer as promotions } from '../modules/promotions'
import { reducer as themes } from '../modules/themes'
import { reducer as members } from '../modules/members'
import { reducer as common } from '../modules/common'

const resettable = resettableReducer('LOGOUT');

export default history =>
  combineReducers({
    auth: auth,
    users: resettable(users),
    organizations: resettable(organizations),
    invites: resettable(invites),
    promotions: resettable(promotions),
    themes: resettable(themes),
    members: resettable(members),
    common: resettable(common),
    confirmationDialog,
    form: formReducer,
    router: connectRouter(history)
  })
