import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import Login from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import Signup from './components/Signup'
import ResetPassword from './components/ResetPassword'

import InvitationAccepted from './components/InvitationAccepted'
import InvitationDeclined from './components/InvitationDeclined'

export default () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/sign-up" component={Signup} />
      <Route path="/invites/:token/accept" component={InvitationAccepted} />
      <Route path="/invites/:token/decline" component={InvitationDeclined} />
      <Route path="/reset-password/:token" component={ResetPassword} />
      <Redirect to="/login" />
    </Switch>
  )
}
