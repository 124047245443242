import React from 'react'

export const Preloader = () => {
  return (
    <div className="theme-loader">
      <div className="ball-scale">
        <div className="contain">
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
          <div className="ring">
            <div className="frame" />
          </div>
        </div>
      </div>
    </div>
  )
}
