import React from 'react'
import {
  Input as ReactInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup
} from 'reactstrap'

// eslint-disable-next-line
export const Input = ({
  input,
  placeholder,
  type,
  required,
  disabled,
  className,
  groupText,
  size,
  meta: { touched, error }
}) => {
  return groupText ? (
    <InputGroup className={className}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>{groupText}</InputGroupText>
      </InputGroupAddon>
      <ReactInput
        {...input}
        type={type || 'text'}
        id={input.name}
        className={touched && error ? 'is-invalid' : ''}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
      />
      {showErrorLabel(touched, error)}
    </InputGroup>
  ) : (
    <FormGroup>
      <ReactInput
        bsSize={size}
        {...input}
        type={type || 'text'}
        id={input.name}
        className={touched && error ? 'is-invalid' : ''}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
      />
      {showErrorLabel(touched, error)}
    </FormGroup>
  )
}

function showErrorLabel(touched, error) {
  if (touched && error) {
    if (Array.isArray(error)) {
      return (
        <div className="invalid-feedback">
          <ul>
            {error.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
        </div>
      )
    }
    return <div className="invalid-feedback">{error}</div>
  }
  return null
}
