import React from 'react'
import { FormGroup, Label, Input as ReactInput } from 'reactstrap'

export const Checkbox = ({
  input,
  disabled,
  label,
  id,
  meta: { touched, error }
}) => {
  return (
    <FormGroup className="mb-3" check>
      <Label check>
        <ReactInput
          {...input}
          id={id}
          disabled={disabled}
          type="checkbox"
          checked={input.checked}
        />
        {label}
      </Label>
      {showErrorLabel(touched, error)}
    </FormGroup>
  )
}

function showErrorLabel(touched, error) {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>
  }
  return null
}
