import React, { Component } from 'react'
import { Preloader } from '../../../../components/common'
import { Card, CardGroup, Col, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { requiredField } from '../../../../utils/form-validator'
import OrganizationForm from './OrganizationForm'

class EditOrganization extends Component {
  componentDidMount() {
    this.loadOrganization()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentOrgId && this.props.currentOrgId) {
      this.loadOrganization()
      return
    }
    if (prevProps.currentOrgId) {
      if (prevProps.currentOrgId === this.props.currentOrgId) {
        return
      }
      // redirect to organizations list if org has been changed
      else {
        this.props.navigateTo('/organizations')
      }
    }
  }

  componentWillMount() {
    if (!this.props.isAdmin) {
      this.props.navigateTo('/organizations')
    }
  }

  componentWillUnmount() {
    this.props.resetEditingOrganization()
  }

  loadOrganization = () => {
    const { editingOrganization, currentOrgId } = this.props
    if (currentOrgId && !editingOrganization) {
      const { id } = this.props.match.params
      this.props.editOrganization(id)
    }
  }

  handleFormSubmit = formProps => {
    const { editingOrganization } = this.props
    const payload = {
      id: editingOrganization.id,
      name: formProps.name,
      memberProgram: formProps.memberProgram
    }
    if (formProps.parentId) {
      payload.parentId = formProps.parentId
    }
    return this.props.organizationSaveRequest(payload)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      fetching,
      organizations,
      editingOrganization,
      currentOrgId
    } = this.props
    if (fetching || !editingOrganization) {
      return <Preloader />
    }
    return (
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <CardGroup>
            <Card className="p-4">
              <OrganizationForm
                onSubmit={handleSubmit(this.handleFormSubmit)}
                formName="editOrgForm"
                label="Edit organization"
                organizations={organizations}
                submitting={submitting}
                editingItem={editingOrganization}
                currentOrgId={currentOrgId}
              />
            </Card>
          </CardGroup>
        </Col>
      </Row>
    )
  }
}

const validate = (values, props) => {
  const { editingOrganization } = props
  const errors = {}
  requiredField(values, errors, 'name')
  if (editingOrganization && editingOrganization.parentId) {
    requiredField(values, errors, 'parentId')
  }
  return errors
}

export default reduxForm({
  validate,
  form: 'editOrgForm'
})(EditOrganization)
