import { call, put, select } from 'redux-saga/effects'
import MembersActions from '../modules/members'
import { showErrorResponse } from '../utils/notification-handler'

import apiServices from '../api-services'
import { callApi, isOk } from './helper'

export function* organizationMembersRequest({ paging }) {
  if (paging) {
    yield put(MembersActions.updateFilter(paging))
  }
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(
    apiServices.getOrganizationMembers,
    orgId,
    paging
  )

  if (isOk(response)) {
    yield put(MembersActions.organizationMembersRequestSuccess(response.data))
  } else {
    yield call(errorHandler, response)
  }
}

export function* getMemberDetails({ id }) {
  yield call(
    getMemberData,
    id,
    apiServices.getMemberDetails,
    MembersActions.onMemberDetailsSuccess
  )
}

export function* getMemberPromotions({ id }) {
  yield call(
    getMemberData,
    id,
    apiServices.getMemberPromotions,
    MembersActions.onMemberPromotionsSuccess
  )
}

export function* getMemberTransactions({ id }) {
  yield call(
    getMemberData,
    id,
    apiServices.getMemberTransactions,
    MembersActions.onMemberTransactionsSuccess
  )
}

export function* getMemberPrizes({ id }) {
  yield call(
    getMemberData,
    id,
    apiServices.getMemberPrizes,
    MembersActions.onMemberPrizesSuccess
  )
}

export function* getMemberSummaries({ id }) {
  yield call(
    getMemberData,
    id,
    apiServices.getMemberSummaries,
    MembersActions.onMemberSummariesSuccess
  )
}

function* errorHandler(response) {
  if (response.data) {
    showErrorResponse(response)
  }
  yield put(MembersActions.onError())
}

function* getMemberData(id, apiFunction, success) {
  const orgId = yield select(
    state => state.organizations.selectedOrganizationId
  )
  const response = yield callApi(apiFunction, orgId, id)
  if (isOk(response)) {
    yield put(success(response.data))
  } else {
    yield call(errorHandler, response)
  }
}
