import { call, put } from 'redux-saga/effects'
import CommonActions from '../../modules/common'
import { showErrorMessage } from '../../utils/notification-handler'
import apiServices from '../../api-services'

export function* callApi(...args) {
  const response = yield call(...args)
  if (!response.ok) {
    switch (response.status) {
      case 401:
        const token = sessionStorage.getItem('refresh_token')
        const refreshResponse = yield call(apiServices.refreshAuthToken, token)
        if (refreshResponse.ok) {
          const { authToken, refreshToken } = refreshResponse.data.token
          sessionStorage.setItem('auth_token', authToken)
          sessionStorage.setItem('refresh_token', refreshToken)
          return yield callApi(...args)
        }
        break
      case 403:
        showErrorMessage('Please, log in.', 'Not authorized.')
        yield put(CommonActions.navigateTo('/login'))
        break
      case 404:
        showErrorMessage('Please, check URL.', 'Not found.')
        break
      default:
        if (!response.data) {
          showErrorMessage(
            'Please, try again or report to system admin.',
            'Unknown Error.'
          )
        }
        break
    }
  }
  return response
}

export const isOk = response => {
  return response.ok && (response.data ? !response.data.isError : true)
}
