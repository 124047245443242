import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import CurrencyInput from 'react-currency-input'

// eslint-disable-next-line
export const CurrencyField = ({
  input,
  required,
  disabled,
  prefix,
  label,
  meta: { touched, error }
}) => {
  return (
    <FormGroup>
      <Label htmlFor={input.name}>{label}</Label>
      <CurrencyInput
        {...input}
        prefix={prefix}
        id={input.name}
        className={`form-control${touched && error ? ' is-invalid' : ''}`}
        disabled={disabled}
        required={required}
      />
      {showErrorLabel(touched, error)}
    </FormGroup>
  )
}

function showErrorLabel(touched, error) {
  if (touched && error) {
    if (Array.isArray(error)) {
      return (
        <div className="invalid-feedback">
          <ul>
            {error.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
        </div>
      )
    }
    return <div className="invalid-feedback">{error}</div>
  }
  return null
}
