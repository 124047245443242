import { connect } from 'react-redux'
import InvitationAccepted from './InvitationAccepted'
import InvitationActions from '../../../../modules/invites'

const mapStateToProps = state => {
  return {
    fetching: state.auth.fetching
  }
}

export default connect(
  mapStateToProps,
  {
    ...InvitationActions
  }
)(InvitationAccepted)
