import React from 'react'
import LoginRoutes from '../../routes/login'

export default () => {
  return (
    <section className="login-block">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <LoginRoutes />
          </div>
        </div>
      </div>
    </section>
  )
}
